/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { off } from 'process'

type Props = {
  name: string,
  chartData: any
}

const XYGraphWidget: React.FC<Props> = ({ name, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartData])

  return (
    <div className={`card  m-1`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{name}</span>
        </h3>
        <div className='card-toolbar' >

        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_4_chart' ></div>
      </div>
    </div>
  )
}

export { XYGraphWidget }

function getChartOptions(height: number, chartData: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-success')
  const baseLightColor = getCSSVariableValue('--bs-success-light')

  const secondaryColor = getCSSVariableValue('--bs-danger')
  const secondaryLightColor = getCSSVariableValue('--bs-danger-light')

  const thirdColor = getCSSVariableValue('--bs-info')
  const thirdLightColor = getCSSVariableValue('--bs-info-light')

  const forthColor = getCSSVariableValue('--bs-warning')
  const forthLightColor = getCSSVariableValue('--bs-warning-light')

  const fifthColor = getCSSVariableValue('--bs-secondary')
  const fifthLightColor = getCSSVariableValue('--bs-secondary-light')

  var x = [];
  if (chartData && chartData.length > 0)
    x = chartData[0].x;

  var maxY = 0;

  for (var i = 0; i < chartData.length; i++) {
    for (var j = 0; j < chartData[i].y.length; j++) {
      if (chartData[i].y[j] > maxY) {
        maxY = chartData[i].y[j];
      }
    }
  }

  var series = chartData.map((item: any) => {
    return {
      name: item.name,
      data: item.y
    }
  });

  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 250,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: x,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: maxY + 1,
      tickAmount: 5,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
    },
    colors: [thirdColor, baseColor, secondaryColor, forthColor, fifthColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [thirdLightColor, baseLightColor, secondaryLightColor, forthLightColor, fifthLightColor],
      strokeColors: [thirdLightColor, baseLightColor, secondaryLightColor, forthLightColor, fifthLightColor],
      strokeWidth: 3,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          height: 500,
        },
        legend: {
          offsetY: 20,
          position: 'bottom',
        }
      }
    }]
  }
}
