/* INVOICE */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'INVOICE/CLEAR_STATE',

    GET_INVOICES: 'INVOICE/GET_INVOICES',
    GET_INVOICES_SUCCESS: 'INVOICE/GET_INVOICES_SUCCESS',
    GET_INVOICES_FAILED: 'INVOICE/GET_INVOICES_FAILED',

    GET_INVOICE: 'INVOICE/GET_INVOICE',
    GET_INVOICE_SUCCESS: 'INVOICE/GET_INVOICE_SUCCESS',
    GET_INVOICE_FAILED: 'INVOICE/GET_INVOICE_FAILED',
    
    PATCH_INVOICE: 'INVOICE/PATCH_INVOICE',
    PATCH_INVOICE_SUCCESS: 'INVOICE/PATCH_INVOICE_SUCCESS',
    PATCH_INVOICE_FAILED: 'INVOICE/PATCH_INVOICE_FAILED',

    POST_INVOICE: 'INVOICE/POST_INVOICE',
    POST_INVOICE_SUCCESS: 'INVOICE/POST_INVOICE_SUCCESS',
    POST_INVOICE_FAILED: 'INVOICE/POST_INVOICE_FAILED',

    PUT_INVOICE_ITEMS: 'INVOICE/PUT_INVOICE_ITEMS',
    PUT_INVOICE_ITEMS_SUCCESS: 'INVOICE/PUT_INVOICE_ITEMS_SUCCESS',
    PUT_INVOICE_ITEMS_FAILED: 'INVOICE/PUT_INVOICE_ITEMS_FAILED',

    POST_TRANSACTION: 'INVOICE/POST_TRANSACTION',
    POST_TRANSACTION_SUCCESS: 'INVOICE/POST_TRANSACTION_SUCCESS',
    POST_TRANSACTION_FAILED: 'INVOICE/POST_TRANSACTION_FAILED',
    
    POST_INVOICE_EMAIL: 'INVOICE/POST_INVOICE_EMAIL',
    POST_INVOICE_EMAIL_SUCCESS: 'INVOICE/POST_INVOICE_EMAIL_SUCCESS',
    POST_INVOICE_EMAIL_FAILED: 'INVOICE/POST_INVOICE_EMAIL_FAILED',

    GET_TRANSACTION: 'INVOICE/GET_TRANSACTION',
    GET_TRANSACTION_SUCCESS: 'INVOICE/GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILED: 'INVOICE/GET_TRANSACTION_FAILED',

    PATCH_TRANSACTION: 'INVOICE/PATCH_TRANSACTION',
    PATCH_TRANSACTION_SUCCESS: 'INVOICE/PATCH_TRANSACTION_SUCCESS',
    PATCH_TRANSACTION_FAILED: 'INVOICE/PATCH_TRANSACTION_FAILED',

    GET_HISTORY: 'INVOICE/GET_HISTORY',
    GET_HISTORY_SUCCESS: 'INVOICE/GET_HISTORY_SUCCESS'

});

export default actionTypes;