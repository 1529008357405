import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {toast} from 'react-toastify';
import {Loader, enumPolicies} from '../../../_metronic/helpers'
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';
import {CardBody, Card, CardHeader, CardTitle} from 'reactstrap';
import {useFormik} from 'formik'

import {clearState, getQuotationSetting, postQuotationSetting, getLookupValues} from './settingActions';
import * as Yup from "yup";

const QuotationSetting = (props) => {
    //inputs from url
    const intl = useIntl()

    //inputs from redux
    const {
        quotation,
        lookups,
        loading,
        success,
        error
    } = props;

    //actions
    const {
        clearState,
        getQuotationSetting,
        postQuotationSetting,
        getLookupValues
    } = props;

    useEffect(() => {

        getQuotationSetting();
        getLookupValues(21);

        return () => {
            clearState()
        }
    }, []);


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success])

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({id: error}));
        }
    }, [error])


    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'SETTING.MANAGEMENT.TITLE'}),
            path: '/setting-management/quotation',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const validationSchema = Yup.object().shape({
        startingQuotationNumber: Yup.number()
            .min(0, intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        quoteExpirationInDays: Yup.number()
            .min(0, intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        documentNumberPrefix: Yup.number()
            .min(0, intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {...quotation},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            postQuotationSetting(values)
        },
    })

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'SETTING.QUOTATION_TITLE'})}</PageTitle>
            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({id: 'SETTING.QUOTATION_TITLE'})}</h3>
                    </CardTitle>
                </CardHeader>
                <CardBody>

                    <form
                        onSubmit={formik.handleSubmit}
                        className='form'
                        noValidate
                    >
                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                <div className='fv-row mb-0'>
                                    <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                                        {intl.formatMessage({id: 'SETTING.QUOTATION_STARTING_NUMBER'})}
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control hide-arrow'
                                        {...formik.getFieldProps('startingQuotationNumber')}
                                    />
                                    {formik.touched.startingQuotationNumber && formik.errors.startingQuotationNumber && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.startingQuotationNumber}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='fv-row mb-0'>
                                    <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                                        {intl.formatMessage({id: 'SETTING.QUOTATION_EXPIRATION_DAYS'})}
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control hide-arrow'
                                        {...formik.getFieldProps('quoteExpirationInDays')}
                                    />
                                    {formik.touched.quoteExpirationInDays && formik.errors.quoteExpirationInDays && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.quoteExpirationInDays}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-12'>
                                <div className='fv-row mb-0'>
                                    <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                                        {intl.formatMessage({id: 'SETTING.QUOTATION_NUMBER_PREFIX'})}
                                    </label>
                                    <select
                                        className='form-control form-select'
                                        {...formik.getFieldProps('documentNumberPrefix')}
                                    >
                                        {lookups && lookups.map((lookup) => {
                                            return (
                                                <option key={`lookup_${lookup.id}`} value={lookup.value}>{lookup.name}</option>
                                            )
                                        })}
                                    </select>
                                    {formik.touched.documentNumberPrefix && formik.errors.documentNumberPrefix && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.documentNumberPrefix}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className='d-flex mt-5'>
                            <button
                                id='kt_password_submit'
                                type='submit'
                                className='btn btn-primary me-2 px-6'
                            >
                                {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
                            </button>
                        </div>
                    </form>

                </CardBody>
            </Card>
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        quotation: state.Setting.quotation,
        lookups: state.Setting.lookups,
        loading: state.Setting.loading,
        success: state.Setting.success,
        error: state.Setting.error
    };
};

const mapActionsToProps = {
    clearState, getQuotationSetting, postQuotationSetting, getLookupValues
}

export default connect(mapStateToProps, mapActionsToProps)(QuotationSetting)