/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useState, useEffect, useRef } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { getAsync, patchAsync } from '../../../helpers/httpClient'
import { useLang, setLanguage } from '../../../i18n/Metronici18n'
import { useIntl } from 'react-intl'

const Languages: FC<any> = ({ userId }) => {
  const lang = useLang()
  const intl = useIntl()

  const [languages, setLanguages] = useState<any>([]);

  useEffect(() => {
    const requestLanguages = async () => {
      try {
        const response = await getAsync("/languages")
        if (response && response.data) {
          setLanguages(response.data.map((item) => {
            return ({
              lang: item.code,
              name: item.name,
              flag: toAbsoluteUrl(item.icon),
            })
          }))
        }
      } catch (error) {
        console.error(error)
      }
    }
    requestLanguages();
  }, [])

  const onlanguagechange = (code) => {
    if (userId) {
      patchAsync(`/users/${userId}/language`, { languageCode: code }).then(() => {
        setLanguage(code)
      });
    } else {
      setLanguage(code)
    }
  }

  const currentLanguage = languages && languages.find(x => x.lang === lang)
  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
        {intl.formatMessage({ id: 'USER.HEADER.LANGUAGE' })}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              onlanguagechange(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Languages }
