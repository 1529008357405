import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify';
import {KTCard, KTCardBody, KTIcon, Loader, enumPolicies} from '../../../_metronic/helpers'
import Moment from 'react-moment';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';
import {Button, Row, Col, CardBody, Card, CardHeader, CardTitle, CardFooter} from 'reactstrap';
import {useFormik} from 'formik'
import IBAN from 'iban'
import lodash from 'lodash'

import {clearState, getInvoiceSetting, postInvoiceSetting, getLookupValues} from './settingActions';
import * as Yup from "yup";

const InvoiceSetting = (props) => {
    //inputs from url
    const intl = useIntl()

    //inputs from redux
    const {
        invoice,
        lookups,
        loading,
        success,
        error
    } = props;

    //actions
    const {
        clearState,
        getInvoiceSetting,
        postInvoiceSetting,
        getLookupValues
    } = props;

    useEffect(() => {

        getInvoiceSetting();
        getLookupValues(21);

        return () => {
            clearState()
        }
    }, []);


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success])

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({id: error}));
        }
    }, [error])


    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'SETTING.MANAGEMENT.TITLE'}),
            path: '/setting-management/invoice',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const validationSchema = Yup.object().shape({
        startingInvoiceNumber: Yup.number()
            .min(0, intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        invoiceNumberPrefix: Yup.number()
            .min(0, intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {...invoice},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            postInvoiceSetting(values)
        },
    })

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'SETTING.INVOICE_TITLE'})}</PageTitle>
            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({id: 'SETTING.INVOICE_TITLE'})}</h3>
                    </CardTitle>
                </CardHeader>
                <CardBody>

                    <form
                        onSubmit={formik.handleSubmit}
                        className='form'
                        noValidate
                    >
                        <div className='row mb-1'>
                            <div className='col-md-12'>
                                <div className='fv-row mb-0'>
                                    <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                                        {intl.formatMessage({id: 'SETTING.INVOICE_STARTING_NUMBER'})}
                                    </label>
                                    <input
                                        type='number'
                                        className='form-control hide-arrow'
                                        {...formik.getFieldProps('startingInvoiceNumber')}
                                    />
                                    {formik.touched.startingInvoiceNumber && formik.errors.startingInvoiceNumber && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.startingInvoiceNumber}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-12'>
                                <div className='fv-row mb-0'>
                                    <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                                        {intl.formatMessage({id: 'SETTING.INVOICE_NUMBER_PREFIX'})}
                                    </label>
                                    <select
                                        className='form-control form-select'
                                        {...formik.getFieldProps('invoiceNumberPrefix')}
                                    >
                                        {lookups && lookups.map((lookup) => {
                                            return (
                                                <option key={`lookup_${lookup.id}`} value={lookup.value}>{lookup.name}</option>
                                            )
                                        })}
                                    </select>
                                    {formik.touched.invoiceNumberPrefix && formik.errors.invoiceNumberPrefix && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.invoiceNumberPrefix}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className='d-flex mt-5'>
                            <button
                                id='kt_password_submit'
                                type='submit'
                                className='btn btn-primary me-2 px-6'
                            >
                                {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
                            </button>
                        </div>
                    </form>

                </CardBody>
            </Card>
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        invoice: state.Setting.invoice,
        lookups: state.Setting.lookups,
        loading: state.Setting.loading,
        success: state.Setting.success,
        error: state.Setting.error
    };
};

const mapActionsToProps = {
    clearState, getInvoiceSetting, postInvoiceSetting, getLookupValues
}

export default connect(mapStateToProps, mapActionsToProps)(InvoiceSetting)