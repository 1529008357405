// @flow
import types from './invoiceConstants';

const INIT_STATE = {
    invoices: [],
    invoice: {id: 0},
    historyList: [],
    hasNextPage: false,
    totalPrice: 0,
    invoiceCounts: {},
    processPayment: null,
    loading: false,
    historyLoading: false,
    success: null
};

const Invoice = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        //Get Invoices
        case types.GET_INVOICES:
            return {
                ...state,
                loading: true
            };
        case types.GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload.data.items,
                hasNextPage: action.payload.data.hasNextPage,
                totalPrice: action.payload.data.totalPrice,
                invoiceCounts: action.payload.data.invoiceCounts,
                loading: false,
                error: null
            };
        case types.GET_INVOICES_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Invoices

        //Get Invoice
        case types.GET_INVOICE:
            return {
                ...state,
                loading: true
            };
        case types.GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_INVOICE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Invoice

        //Patch Invoice
        case types.PATCH_INVOICE:
            return {
                ...state,
                error: null,
                success: null,
                loading: true
            };
        case types.PATCH_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "INVOICE.PATCH_INVOICE_SUCCESS",
                error: null
            };
        case types.PATCH_INVOICE_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Patch Invoice

        //Post Invoice
        case types.POST_INVOICE:
            return {
                ...state,
                loading: true
            };
        case types.POST_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                invoice: action.payload.data,
                success: "INVOICE.POST_INVOICE_SUCCESS",
                error: null
            };
        case types.POST_INVOICE_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Invoice

        //Put Invoice Items
        case types.PUT_INVOICE_ITEMS:
            return {
                ...state,
                loading: true
            };
        case types.PUT_INVOICE_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "INVOICE.PUT_INVOICE_ITEMS_SUCCESS",
                error: null
            };
        case types.PUT_INVOICE_ITEMS_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Put Invoice Items


        //Post Transaction
        case types.POST_TRANSACTION:
            return {
                ...state,
                loading: true
            };
        case types.POST_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "INVOICE.POST_TRANSACTION_SUCCESS",
                error: null
            };
        case types.POST_TRANSACTION_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };

        case types.GET_TRANSACTION:
            return {
                ...state,
                loading: true
            };
        case types.GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                processPayment: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_TRANSACTION_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };

        case types.PATCH_TRANSACTION:
            return {
                ...state,
                loading: true
            };
        case types.PATCH_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "INVOICE.PATCH_TRANSACTION_SUCCESS",
                processPayment: null,
                error: null
            };
        case types.PATCH_TRANSACTION_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Transaction


        //Post Invoice Email
        case types.POST_INVOICE_EMAIL:
            return {
                ...state,
                loading: true
            };
        case types.POST_INVOICE_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: "INVOICE.POST_INVOICE_EMAIL_SUCCESS",
                error: null
            };
        case types.POST_INVOICE_EMAIL_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //Post Invoice Email

        //history
        case types.GET_HISTORY:
            return {
                ...state,
                historyLoading: true,
                error: null
            };
        case types.GET_HISTORY_SUCCESS:
            return {
                ...state,
                historyList: action.payload.data,
                historyLoading: false,
                error: null
            };
        //history

        default:
            return state;
    }
};

export default Invoice;
