import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import UsersPage from '../pages/user-management/users'
import DealersPage from '../pages/user-management/dealers'
import UserPage from '../pages/user-management/user'
import CustomersPage from '../pages/customer-management/customers'
import CustomerPage from '../pages/customer-management/customer'
import ProfilePage from '../pages/profile/profile'
import ProductsPage from '../pages/catalog-management/product/products'
import ProductPage from '../pages/catalog-management/product/product'
import SuppliersPage from '../pages/catalog-management/supplier/suppliers'
import SupplierPage from '../pages/catalog-management/supplier/supplier'
import ProductDetailPage from '../pages/catalog-management/product/product-detail'
import StocksPage from '../pages/stock-management/stock/stocks'
import StockPage from '../pages/stock-management/stock/stock'
import StockOperation from '../pages/stock-management/stock/stock-operation'
import DevSupportTicketsPage from '../pages/dev-support/devSupportTickets'
import DevSupportTicketPage from '../pages/dev-support/devSupportTicket'
import PrintPage from '../pages/stock-management/stock/print'
import VerandaPage from '../pages/price-management/veranda'
import UnitPage from '../pages/price-management/unit'
import Checkout from '../pages/checkout/checkout'
import CheckoutSummary from '../pages/checkout/checkout-summary'
import CheckoutThanks from '../pages/checkout/checkout-thanks'
import Orders from '../pages/order/orders'
import Order from '../pages/order/order'
import Pay from '../pages/checkout/pay'
import Contents from '../pages/localization/contents'
import Resources from '../pages/localization/resources'
import Leads from '../pages/lead-management/leads'
import Invoices from '../pages/invoice/invoices'
import Invoice from '../pages/invoice/invoice'
import Quotes from '../pages/quote/quotes'
import Quote from '../pages/quote/quote'
import CompanySetting from '../pages/setting/companySetting'
import InvoiceSetting from "../pages/setting/invoiceSetting";
import QuotationSetting from "../pages/setting/quotationSetting";

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />

        {/* Lazy Modules */}

        <Route path='user-management/users' element={<UsersPage />} />
        <Route path='user-management/dealers' element={<DealersPage />} />
        <Route path='user-management/users/:id' element={<UserPage />} />

        <Route path='customer-management/customers' element={<CustomersPage />} />
        <Route path='customer-management/customers/:id' element={<CustomerPage />} />

        <Route path='catalog-management/products' element={<ProductsPage />} />
        <Route path='catalog-management/products/:id' element={<ProductPage />} />
        <Route path='catalog-management/products/:id/detail' element={<ProductDetailPage />} />

        <Route path='catalog-management/suppliers' element={<SuppliersPage />} />
        <Route path='catalog-management/suppliers/:id' element={<SupplierPage />} />

        <Route path='stock-management/stocks' element={<StocksPage />} />
        <Route path='stock-management/stocks/:id' element={<StockPage />} />

        <Route path='price-management/veranda' element={<VerandaPage />} />
        <Route path='price-management/unit' element={<UnitPage />} />

        <Route path='checkout-management/checkout' element={<Checkout />} />
        <Route path='checkout-management/checkout-summary' element={<CheckoutSummary />} />
        <Route path='checkout-management/pay' element={<Pay />} />
        <Route path='checkout-management/checkout-thanks/:id' element={<CheckoutThanks />} />

        <Route path='order-management/orders' element={<Orders />} />
        <Route path='order-management/orders/:id' element={<Order />} />

        <Route path='order-management/invoices' element={<Invoices />} />
        <Route path='order-management/invoices/:id' element={<Invoice />} />

        <Route path='order-management/quotes' element={<Quotes />} />
        <Route path='order-management/quotes/:id' element={<Quote />} />

        <Route path="stock-management/stocks-operation" element={<StockOperation />}>
          <Route path=":id" element={<StockOperation />} />
        </Route>

        <Route path="stock-management/stocks-print" element={<PrintPage />}>
          <Route path=":id" element={<PrintPage />} />
        </Route>

        <Route path='profile' element={<ProfilePage />} />

        {/* Help */}
        <Route path='dev-support/tickets' element={<DevSupportTicketsPage />} />
        <Route path='dev-support/tickets/:id' element={<DevSupportTicketPage />} />

        <Route path='localization/contents' element={<Contents />} />
        <Route path='localization/resources' element={<Resources />} />

        <Route path='lead-management/leads' element={<Leads />} />

        <Route path='setting-management/company' element={<CompanySetting />} />
        <Route path='setting-management/invoice' element={<InvoiceSetting />} />
        <Route path='setting-management/quotation' element={<QuotationSetting />} />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
