// @flow
import types from './customerConstants';
import { getAsync, patchAsync, postAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getCustomerList = (keyword = '', isActive = '') => async dispatch => {

    dispatch({
        type: types.GET_CUSTOMERS
    })

    const response = await getAsync(`/customers?keyword=${keyword}&isActive=${isActive}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CUSTOMERS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CUSTOMERS_FAILED,
            payload: response,
        })
    }
}

export const getCustomer = (id) => async dispatch => {

    dispatch({
        type: types.GET_CUSTOMER
    })

    const response = await getAsync(`/customers/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CUSTOMER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.GET_CUSTOMER_FAILED,
            payload: response
        })
    }
}

export const postCustomer = (customer) => async dispatch => {

    dispatch({
        type: types.POST_CUSTOMER
    })

    const response = await postAsync(`/customers`, customer);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_CUSTOMER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.POST_CUSTOMER_FAILED,
            payload: response
        })
    }
}

export const patchCustomer = (id, request) => async dispatch => {

    dispatch({
        type: types.PATCH_CUSTOMER
    })

    const response = await patchAsync(`/customers/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_CUSTOMER_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_CUSTOMER_FAILED,
            payload: response
        })
    }
}

export const changeStatus = (id, isActive) => async dispatch => {

    dispatch({
        type: types.PATCH_CUSTOMER_STATUS
    })

    var request = {
        isActive: isActive
    };

    const response = await patchAsync(`/customers/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_CUSTOMER_STATUS_SUCCESS,
            payload: { id, isActive }
        })
    } else {
        dispatch({
            type: types.PATCH_CUSTOMER_STATUS_FAILED,
            payload: id,
        })
    }
}
