import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { AvForm, AvField, } from 'availity-reactstrap-validation';
import { Button, Row, Col } from 'reactstrap';
import { KTCard, KTCardBody, Loader, enumCustomerSourceType } from '../../../_metronic/helpers'
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { Audit } from '../../../_metronic/helpers/components/Audit';

import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getAsync } from '../../../_metronic/helpers/httpClient'

import LanguageSelector from '../../modules/auth/components/LanguageSelector';

import { clearState, getCustomer, postCustomer, patchCustomer } from './customerActions';

const Customer = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    //inputs from redux
    const {
        customer,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getCustomer,
        postCustomer,
        patchCustomer
    } = props;

    const [languages, setLanguages] = useState([]);
    const [customerLanguage, setCustomerLanguage] = useState('en');
    useEffect(() => {
        if (params.id > 0) {
            getCustomer(params.id);
        }

        const requestLanguages = async () => {
            try {
            const response = await getAsync("/languages")
            if (response && response.data) {
                setLanguages(response.data.map((item) => {
                return ({
                    lang: item.code,
                    name: item.name,
                    flag: toAbsoluteUrl(item.icon),
                })
                }))
            }
            } catch (error) {
                console.error(error)
            }
        }
        requestLanguages();

        return () => {
            clearState()
        }

    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    useEffect(() => {

        if (params.id === '0' && customer && customer.id > 0) {
            navigate(`/customer-management/customers/${customer.id}`);
        }

        if (customer && customer.languageCode)
            setCustomerLanguage(customer.languageCode)

    }, [customer]);

    const customersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CUSTOMER.MANAGEMENT.TITLE' }),
            path: '/customer-management/customers',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const handleValidSubmit = (event, values) => {
        if (customer.id > 0) {
            patchCustomer(params.id, {
                name: {
                    set: true,
                    value: values.name
                },
                surname: {
                    set: true,
                    value: values.surname
                },
                email: {
                    set: true,
                    value: values.email
                },
                phone: {
                    set: true,
                    value: values.phone
                },
                address: {
                    set: true,
                    value: values.address
                },
                languageCode: {
                    set: true,
                    value: customerLanguage
                }
            })
        } else {
            postCustomer({ 
                ...values,
                languageCode: customerLanguage
            })
        }
    }

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const handleLanguageChange = (language) => {
        setCustomerLanguage(language.lang)
    };

    const renderLeadDataRow = () => {
        if (customer && customer.adsLeadFormFields)
        {
            return (
                <Row>
                    <Col>
                        <Card>
                            <CardBody style={{ overflowY: "auto" }}>
                                {customer.adsLeadFormFields.map((field, index) => {
                                    return (
                                        <p key={`lead_formfield_${index}`} className='mb-1'>
                                            <span className="text-gray-800 fw-bold fs-6 me-1" style={{ wordBreak: "break-word" }}> {field.fieldKey}</span>
                                            <span className="fs-6 text-danger" style={{ wordBreak: "break-word" }}>{field.fieldValue}</span>
                                        </p>
                                    )
                                })}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        }
    }

    const renderCustomerForm = () => {
        return (
            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <h3 >{intl.formatMessage({ id: 'CUSTOMER.TITLE' })}</h3>
                    </div>
                    <div className="card-toolbar">

                        <LanguageSelector 
                            languages={languages}
                            currentLanguageCode={customerLanguage}
                            onLanguageChange={handleLanguageChange}
                        />
                        <Audit table="Customer" identifier={customer.id} />
                    </div>
                </div>
                <KTCardBody>
                    <AvForm key={1} onValidSubmit={handleValidSubmit}>
                        {
                            customer && customer.id > 0 && params.id > 0 &&
                            <Row>
                                <Col>
                                    <AvField name="relationshipNr" label={intl.formatMessage({ id: 'CUSTOMER.RELATIONSHIPNR' })} type="text" value={customer && customer.relationshipNr} readOnly={true}/>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <AvField name="name" label={intl.formatMessage({ id: 'CUSTOMER.NAME' })} type="text" value={customer && customer.name} validate={validation} />
                            </Col>
                            <Col>
                                <AvField name="surname" label={intl.formatMessage({ id: 'CUSTOMER.SURNAME' })} type="text" value={customer && customer.surname} validate={validation} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField name="email" label={intl.formatMessage({ id: 'CUSTOMER.EMAIL' })} type="text" value={customer && customer.email}/>
                            </Col>
                            <Col>
                                <AvField name="phone" label={intl.formatMessage({ id: 'CUSTOMER.PHONE' })} type="text" value={customer && customer.phone} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvField name="address" label={intl.formatMessage({ id: 'CUSTOMER.ADDRESS' })} type="text" value={customer && customer.address} />
                            </Col>
                        </Row>
                        {
                            customer && customer.id > 0 && params.id > 0 &&
                            <Row>
                                <Col>

                                    <AvField name="source" label={intl.formatMessage({ id: 'CUSTOMER.SOURCE' })} type="text" value={customer && customer.customerSource && 
                                                                (customer.customerSource === enumCustomerSourceType.Manual ? `${intl.formatMessage({ id: 'CUSTOMER.SOURCE_MANUAL' })}` : `${intl.formatMessage({ id: 'CUSTOMER.SOURCE_LEAD' })}`)}
                                                                readOnly={true} />
                                </Col>
                            </Row>
                        }
                        {renderLeadDataRow()}
                        <AvField type="hidden" name="id" value={customer && customer.id} />
                        <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                    </AvForm>
                </KTCardBody>
            </KTCard >
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={customersBreadcrumbs}>{intl.formatMessage({ id: params.id > 0 ? 'CUSTOMER.EDIT.BREADCRUMB' : 'CUSTOMER.CREATE.BREADCRUMB' })}</PageTitle>

            {renderCustomerForm()}

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        customer: state.Customer.customer,
        loading: state.Customer.loading,
        success: state.Customer.success
    };
};

const mapActionsToProps = {
    clearState, getCustomer, postCustomer, patchCustomer
}

export default connect(mapStateToProps, mapActionsToProps)(Customer)