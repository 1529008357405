/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  name: string,
  chartData: any
}

const PieChartGraphWidget: React.FC<Props> = ({ name, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData))
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartData])

  return (
    <div className={`card m-1`}>
      <div className='card-header border-0 pt-5 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{name}</span>
        </h3>
        <div className='card-toolbar' >

        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_4_chart' ></div>
      </div>
    </div>
  )
}

export { PieChartGraphWidget }

function getChartOptions(height: number, chartData: any): ApexOptions {

  var labels = [];
  if (chartData && chartData.length > 0) {
    labels = chartData[0].x;
  }

  var series = [];
  if (chartData && chartData.length > 0) {
    series = chartData[0].y;
  }

  return {
    series: series,
    chart: {
      type: 'pie',
      fontFamily: 'inherit',
      height: 260,
      toolbar: {
        show: false,
      },
    },
    labels: labels,
    legend: {
      position: 'right',
      offsetY: 0,
      height: 260,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          height: 520,
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }
}
