import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify';
import {KTCard, KTCardBody, KTIcon, Loader, enumPolicies} from '../../../_metronic/helpers'
import Moment from 'react-moment';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';
import {Button, Row, Col, CardBody, Card, CardHeader, CardTitle, CardFooter} from 'reactstrap';
import {useFormik} from 'formik'
import IBAN from 'iban'
import lodash from 'lodash'

import {clearState, getCompanySetting, postCompanySetting} from './settingActions';
import * as Yup from "yup";

const CompanySetting = (props) => {
    //inputs from url
    const intl = useIntl()

    //inputs from redux
    const {
        company,
        loading,
        success,
        error
    } = props;

    //actions
    const {
        clearState,
        getCompanySetting,
        postCompanySetting
    } = props;

    useEffect(() => {

        getCompanySetting();

        return () => {
            clearState()
        }
    }, []);


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success])

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({id: error}));
        }
    }, [error])


    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'SETTING.MANAGEMENT.TITLE'}),
            path: '/setting-management/company',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const validationSchema = Yup.object().shape({
        companyName: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        phone: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        email: Yup.string()
            .email(intl.formatMessage({id: "VALIDATION.WRONG_FORMAT"}))
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        street: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        building: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        zipCode: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        city: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        country: Yup.string()
            .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
        financialSettings: Yup.object().shape({
            iban: Yup.string()
                .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
                .test(
                    "iban",
                    intl.formatMessage({id: "VALIDATION.WRONG_FORMAT"}),
                    (value) => IBAN.isValid(value)
                ),
            kvkNumber: Yup.string()
                .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
                .matches(RegExp("^[0-9]{8}$"), {message: intl.formatMessage({id: "VALIDATION.WRONG_FORMAT"})}),
            btwNumber: Yup.string()
                .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
                .matches(RegExp("^[A-Za-z]{2}[0-9]{9}[A-Za-z][0-9]{2}$"), {message: intl.formatMessage({id: "VALIDATION.WRONG_FORMAT"})}),
            accountHolderName: Yup.string()
                .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"})),
            bic: Yup.string()
                .required(intl.formatMessage({id: "VALIDATION.REQUIRED_FIELD"}))
        })
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {...company},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            postCompanySetting(values)
        },
    })

    const renderElement = (label, name) => {
        const isTouched = lodash.get(formik.touched, name); // Güvenli erişim
        const errorMessage = lodash.get(formik.errors, name); //
        return (
            <div className='fv-row mb-0'>
                <label className='form-label fs-6 fw-bolder mb-3 mt-5'>
                    {intl.formatMessage({id: label})}
                </label>
                <input
                    type='text'
                    className='form-control '
                    id='text'
                    {...formik.getFieldProps(name)}
                />
                {isTouched && errorMessage && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errorMessage}</div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'SETTING.COMPANY_TITLE'})}</PageTitle>
            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({id: 'SETTING.COMPANY_TITLE'})}</h3>
                    </CardTitle>
                </CardHeader>
                <CardBody>

                    <form
                        onSubmit={formik.handleSubmit}
                        className='form'
                        noValidate
                    >
                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_NAME', 'companyName')}
                            </div>

                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_EMAIL', 'email')}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_PHONE', 'phone')}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_STREET', 'street')}
                            </div>

                            <div className='col-md-3'>
                                {renderElement('SETTING.COMPANY_BUILDING', 'building')}
                            </div>

                            <div className='col-md-3'>
                                {renderElement('SETTING.COMPANY_ZIP_CODE', 'zipCode')}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_CITY', 'city')}
                            </div>

                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_COUNTRY', 'country')}
                            </div>
                        </div>


                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_ACCOUNT_HOLDER', 'financialSettings.accountHolderName')}
                            </div>

                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_IBAN', 'financialSettings.iban')}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_BTW_NUMBER', 'financialSettings.btwNumber')}
                            </div>

                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_KVK_NUMBER', 'financialSettings.kvkNumber')}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <div className='col-md-6'>
                                {renderElement('SETTING.COMPANY_BIC', 'financialSettings.bic')}
                            </div>

                        </div>

                        <div className='d-flex mt-5'>
                            <button
                                id='kt_password_submit'
                                type='submit'
                                className='btn btn-primary me-2 px-6'
                            >
                                {intl.formatMessage({id: 'GENERAL.SUBMIT'})}
                            </button>
                        </div>
                    </form>

                </CardBody>
            </Card>
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        company: state.Setting.company,
        loading: state.Setting.loading,
        success: state.Setting.success,
        error: state.Setting.error
    };
};

const mapActionsToProps = {
    clearState, getCompanySetting, postCompanySetting
}

export default connect(mapStateToProps, mapActionsToProps)(CompanySetting)