/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getAsync } from '../../../_metronic/helpers/httpClient'
import { useLang, setLanguage } from '../../../_metronic/i18n/Metronici18n'
import { useIntl } from 'react-intl'

import LanguageSelector from './components/LanguageSelector';

const AuthLayout = () => {

  const intl = useIntl();

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const lang = useLang()
  const [languages, setLanguages] = useState<any>([]);
  useEffect(() => {
    const requestLanguages = async () => {
      try {
        const response = await getAsync("/languages")
        if (response && response.data) {
          setLanguages(response.data.map((item) => {
            return ({
              lang: item.code,
              name: item.name,
              flag: toAbsoluteUrl(item.icon),
            })
          }))
        }
      } catch (error) {
        console.error(error)
      }
    }
    requestLanguages();
  }, [])

  const handleLanguageChange = (language) => {
    setLanguage(language.lang)
  };

  const currentLanguage = languages && languages.find(x => x.lang === lang)

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          <LanguageSelector 
            languages={languages}
            currentLanguageCode={currentLanguage && currentLanguage.lang}
            onLanguageChange={handleLanguageChange}
          />
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-success fs-base'>
            <a href='#' className='px-5' target='_blank'>
            {intl.formatMessage({ id: 'AUTH.LOGIN.CONTACT.US' })}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url("https://storage.googleapis.com/thegardengallery-prod/company/the-garden-gallery-2.jpg")` }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-end py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img alt='Logo' src="https://storage.googleapis.com/thegardengallery-prod/company/logo.png" className='h-75px' />
          </Link>
          {/* end::Logo */}

        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export { AuthLayout }
