/* QUOTE */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'QUOTE/CLEAR_STATE',

    GET_QUOTES: 'QUOTE/GET_QUOTES',
    GET_QUOTES_SUCCESS: 'QUOTE/GET_QUOTES_SUCCESS',
    GET_QUOTES_FAILED: 'QUOTE/GET_QUOTES_FAILED',

    GET_QUOTE: 'QUOTE/GET_QUOTE',
    GET_QUOTE_SUCCESS: 'QUOTE/GET_QUOTE_SUCCESS',
    GET_QUOTE_FAILED: 'QUOTE/GET_QUOTE_FAILED',
    
    PATCH_QUOTE: 'QUOTE/PATCH_QUOTE',
    PATCH_QUOTE_SUCCESS: 'QUOTE/PATCH_QUOTE_SUCCESS',
    PATCH_QUOTE_FAILED: 'QUOTE/PATCH_QUOTE_FAILED',

    POST_QUOTE: 'QUOTE/POST_QUOTE',
    POST_QUOTE_SUCCESS: 'QUOTE/POST_QUOTE_SUCCESS',
    POST_QUOTE_FAILED: 'QUOTE/POST_QUOTE_FAILED',

    POST_QUOTE_CART: 'QUOTE/POST_QUOTE_CART',
    POST_QUOTE_CART_SUCCESS: 'QUOTE/POST_QUOTE_CART_SUCCESS',
    POST_QUOTE_CART_FAILED: 'QUOTE/POST_QUOTE_CART_FAILED',

    PUT_QUOTE_ITEMS: 'QUOTE/PUT_QUOTE_ITEMS',
    PUT_QUOTE_ITEMS_SUCCESS: 'QUOTE/PUT_QUOTE_ITEMS_SUCCESS',
    PUT_QUOTE_ITEMS_FAILED: 'QUOTE/PUT_QUOTE_ITEMS_FAILED',

    POST_TRANSACTION: 'QUOTE/POST_TRANSACTION',
    POST_TRANSACTION_SUCCESS: 'QUOTE/POST_TRANSACTION_SUCCESS',
    POST_TRANSACTION_FAILED: 'QUOTE/POST_TRANSACTION_FAILED',
    
    POST_QUOTE_EMAIL: 'QUOTE/POST_QUOTE_EMAIL',
    POST_QUOTE_EMAIL_SUCCESS: 'QUOTE/POST_QUOTE_EMAIL_SUCCESS',
    POST_QUOTE_EMAIL_FAILED: 'QUOTE/POST_QUOTE_EMAIL_FAILED',
    
    POST_QUOTE_ACCEPT: 'QUOTE/POST_QUOTE_ACCEPT',
    POST_QUOTE_ACCEPT_SUCCESS: 'QUOTE/POST_QUOTE_ACCEPT_SUCCESS',
    POST_QUOTE_ACCEPT_FAILED: 'QUOTE/POST_QUOTE_ACCEPT_FAILED',
    
    POST_QUOTE_INVOICED: 'QUOTE/POST_QUOTE_INVOICED',
    POST_QUOTE_INVOICED_SUCCESS: 'QUOTE/POST_QUOTE_INVOICED_SUCCESS',
    POST_QUOTE_INVOICED_FAILED: 'QUOTE/POST_QUOTE_INVOICED_FAILED',
    
    POST_QUOTE_REJECT: 'QUOTE/POST_QUOTE_ACCEPT',
    POST_QUOTE_REJECT_SUCCESS: 'QUOTE/POST_QUOTE_REJECT_SUCCESS',
    POST_QUOTE_REJECT_FAILED: 'QUOTE/POST_QUOTE_REJECT_FAILED',
    
    POST_QUOTE_CONVERT_TO_ORDER: 'QUOTE/POST_QUOTE_CONVERT_TO_ORDER',
    POST_QUOTE_CONVERT_TO_ORDER_SUCCESS: 'QUOTE/POST_QUOTE_CONVERT_TO_ORDER_SUCCESS',
    POST_QUOTE_CONVERT_TO_ORDER_FAILED: 'QUOTE/POST_QUOTE_CONVERT_TO_ORDER_FAILED',

    GET_TRANSACTION: 'QUOTE/GET_TRANSACTION',
    GET_TRANSACTION_SUCCESS: 'QUOTE/GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILED: 'QUOTE/GET_TRANSACTION_FAILED',

    PATCH_TRANSACTION: 'QUOTE/PATCH_TRANSACTION',
    PATCH_TRANSACTION_SUCCESS: 'QUOTE/PATCH_TRANSACTION_SUCCESS',
    PATCH_TRANSACTION_FAILED: 'QUOTE/PATCH_TRANSACTION_FAILED',

    GET_HISTORY: 'QUOTE/GET_HISTORY',
    GET_HISTORY_SUCCESS: 'QUOTE/GET_HISTORY_SUCCESS'

});

export default actionTypes;