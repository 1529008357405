/* LEADS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'LEAD/CLEAR_STATE',

    GET_LEADS: 'LEAD/GET_LEADS',
    GET_LEADS_SUCCESS: 'LEAD/GET_LEADS_SUCCESS',
    GET_LEADS_FAILED: 'LEAD/GET_LEADS_FAILED',

    GET_LEAD: 'LEAD/GET_LEAD',
    GET_LEAD_SUCCESS: 'LEAD/GET_LEAD_SUCCESS',
    GET_LEAD_FAILED: 'LEADSGET_LEAD_FAILED',
    
    GET_CAMPAIGNS: 'LEAD/GET_CAMPAIGNS',
    GET_CAMPAIGNS_SUCCESS: 'LEAD/GET_CAMPAIGNS_SUCCESS',
    GET_CAMPAIGNS_FAILED: 'LEAD/GET_CAMPAIGNS_FAILED',

    PATCH_LEAD: 'LEAD/PATCH_LEAD',
    PATCH_LEAD_SUCCESS: 'LEAD/PATCH_LEAD_SUCCESS',
    PATCH_LEAD_FAILED: 'LEAD/PATCH_LEAD_FAILED',

    GET_USERS: 'LEAD/GET_USERS',
    GET_HISTORY: 'LEAD/GET_HISTORY',

    POST_COMMENT: 'LEAD/POST_COMMENT',
    POST_COMMENT_SUCCESS: 'LEAD/POST_COMMENT_SUCCESS',
    POST_COMMENT_FAILED: 'LEAD/POST_COMMENT_FAILED',
    GET_HISTORY: 'LEAD/GET_HISTORY',

    POST_CONVERT_TO_CUSTOMER: 'LEAD/POST_COMMENT',
    POST_CONVERT_TO_CUSTOMER_SUCCESS: 'LEAD/POST_CONVERT_TO_CUSTOMER_SUCCESS',
    POST_CONVERT_TO_CUSTOMER_FAILED: 'LEAD/POST_CONVERT_TO_CUSTOMER_FAILED'

});

export default actionTypes;