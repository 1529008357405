
const QuotePrint = (props) => {

    const { quote, component, intl } = props;


    if (quote === undefined || quote === null || quote.id == 0) {
        return null;
    }

    const renderHtml = (text) => {
        if (text === null || text === undefined) {
            return <></>;
        }
        return (
            <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }}></div>
        )
    }

    var pageStyle = `
  
   @page {
       size: A4;
       margin: 20mm;
   }
   body {
       font-family: Arial, sans-serif;
       margin: 0;
       padding: 0;
       width: 100%;
   }
   .container {
       width: 210mm;
       padding: 20mm;
       margin: auto;
   }
   .header, .footer {
       text-align: left;
       margin-bottom: 20px;
   }
       .header{
       height: 100px;
       }
   .header h1 {
       font-size: 24px;
   }
   .header p {
       margin: 0;            
   }
                .logo{
       margin-bottom: 50px;
       }
   .client-info, .company-info {
        height: 150px;
   }        
   .quote-title {
       font-size: 22px;
       margin-bottom: 10px;
   }
       .quote-details{
       display: inline-flex;
       }
       .quote-details p {
       margin-right: 50px;
       }
   table {
       width: 100%;
       border-collapse: collapse;
       margin-top: 20px;
   }
   th, td {
       padding: 8px;
       text-align: left;
       border-bottom: 1px solid #ddd;
   }
   th {
       background-color: #f2f2f2;
   }
   .totals {
       margin-top: 20px;
   }
   .totals p {
       margin: 4px 0;
       text-align: right;
   }
   .bold {
       font-weight: bold;
   }
   .small-text {
       font-size: 0.8em;
       color: #666;
   }
         .total-section {

       }
       .total-section td{
       border:none;
       }

       .border-top-solid{
       border-top: 1px solid black !important;
       }

       `;

    var componentStyle = `
  
   .header, .footer {

       text-align: left;
       margin-bottom: 20px;
   }
       .header{
       height: 50px;
       }
   .header h1 {
       font-size: 24px;
   }
   .header p {
       margin: 0;            
   }
         .logo{
       margin-bottom: 50px;
       }
   .client-info, .company-info {
        height: 150px;
   }        
   .quote-title {
       font-size: 22px;
       margin-bottom: 10px;
   }
       .quote-details{
       display: inline-flex;
       }
       .quote-details p {
       margin-right: 50px;
       }
   table {
       width: 100%;
       border-collapse: collapse;
       margin-top: 20px;
   }
   th, td {
       padding: 8px;
       text-align: left;
       border-bottom: 1px solid #ddd;
   }
   th {
       background-color: #f2f2f2;
   }
   .totals {
       margin-top: 20px;
   }
   .totals p {
       margin: 4px 0;
       text-align: right;
   }
   .bold {
       font-weight: bold;
   }
   .small-text {
       font-size: 0.8em;
       color: #666;
   }
         .total-section {

       }
       .total-section td{
       border:none;
       }

       .border-top-solid{
       border-top: 1px solid black !important;
       }

       `;

    return (
        <>
            <title>&nbsp;</title>
            <style>
                {component ? componentStyle : pageStyle}
            </style>
            <body style={{ minWidth: "700px" }}>
                <div className="container">
                    <div className="header">
                        <p>{quote.kvk}</p>
                    </div>

                    <div className="logo">
                        <img src="https://storage.googleapis.com/thegardengallery-prod/company/logo.png" width={300}></img>
                    </div>

                    <div className="client-info">
                        {renderHtml(quote.customContact)}
                    </div>

                    <div className="quote-title">{intl.formatMessage({ id: 'PRINT.QUOTE' })}</div>

                    <div className="quote-details">
                        <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_NUMBER'})}</strong><br></br> {quote.quoteNr}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_DATE'})}</strong><br></br> {quote.quoteDate}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_EXPIRE_DATE'})}</strong><br></br> {quote.expiryDate}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.QUOTE_RELATIONSHIPNR'})}</strong><br></br> {quote.relationshipNr}</p>
                    </div>

                    <table>
                        <thead>
                        <tr>
                            <th>{intl.formatMessage({id: 'PRINT.QUOTE_Q'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.QUOTE_DESCRIPTION'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.QUOTE_PRICE'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.QUOTE_AMOUNT'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.QUOTE_VAT'})}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                                quote && quote.quoteItems && quote.quoteItems.map((item, index) => {
                                    return (
                                        <tr key={`table_row_${index}`}>
                                            <td>{item.quantity}</td>
                                            <td style={{ wordBreak: "break-all", width: "300px" }}>{renderHtml(item.description)}</td>
                                            <td>€{item.unitPrice}</td>
                                            <td>€{item.totalPrice}</td>
                                            <td>{item.vat}%</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                        <tfoot className="total-section">

                        <tr>
                            <td colSpan={2}></td>
                            <td>{intl.formatMessage({id: 'PRINT.TOTAL_EXCLUSIEF_BTW'})}</td>
                            <td>€{quote.totalPrice}</td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td>{intl.formatMessage({id: 'PRINT.TOTAL_BTW'})}</td>
                            <td>€{quote.vatPrice}</td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td colSpan={3}></td>
                            </tr>

                            <tr >
                                <td colSpan={2}></td>
                                <td className="bold border-top-solid">{intl.formatMessage({id: 'PRINT.TOTAL_INC_BTW'})}</td>
                                <td className='border-top-solid'>€{quote.totalPriceIncVat}</td>
                                <td colSpan={1} className="border-top-solid"></td>
                            </tr>
                        </tfoot>
                    </table>

                    <div className="footer small-text">
                        <p> </p>
                    </div>
                </div>
            </body>
        </>
    );
};


export default QuotePrint;