/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {enumPolicies} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import PolicyChecker from '../../../../helpers/components/PolicyChecker';

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='element-11'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            <PolicyChecker
                policies={[enumPolicies.UserWrite, enumPolicies.UserEdit, enumPolicies.UserWrite, enumPolicies.UserDealerRead, enumPolicies.ProductRead, enumPolicies.ProductEdit, enumPolicies.ProductWrite, enumPolicies.ProductPriceRead, enumPolicies.PriceRead, enumPolicies.PriceEdit, enumPolicies.StockRead, enumPolicies.StockEdit, enumPolicies.StockWrite]}>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.PAGES'})}</span>
                    </div>
                </div>
            </PolicyChecker>


            <PolicyChecker policies={[
                enumPolicies.UserWrite, enumPolicies.UserEdit, enumPolicies.UserWrite, enumPolicies.UserDealerRead,
                enumPolicies.CustomerRead, enumPolicies.CustomerEdit, enumPolicies.CustomerWrite
            ]}
                >
                <SidebarMenuItemWithSub
                    to='/user-management'
                    icon='profile-user'
                    title={intl.formatMessage({id: 'USER.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >

                    <PolicyChecker policies={[enumPolicies.UserWrite, enumPolicies.UserEdit, enumPolicies.UserWrite]}>
                        <SidebarMenuItem to='/user-management/users' title={intl.formatMessage({id: 'USER.MANAGEMENT.USER_TITLE'})} hasBullet={true}/>
                    </PolicyChecker>

                    <PolicyChecker policies={[enumPolicies.UserDealerRead]}>
                        <SidebarMenuItem to='/user-management/dealers' title={intl.formatMessage({id: 'USER.MANAGEMENT.DEALER_TITLE'})} hasBullet={true}/>
                    </PolicyChecker>

                    <PolicyChecker policies={[enumPolicies.CustomerRead, enumPolicies.CustomerEdit, enumPolicies.CustomerWrite]}>
                        <SidebarMenuItem to='/customer-management/customers' title={intl.formatMessage({id: 'CUSTOMER.MANAGEMENT.CUSTOMER_TITLE'})} hasBullet={true}/>
                    </PolicyChecker>

                </SidebarMenuItemWithSub>
            </PolicyChecker>


            <PolicyChecker policies={[enumPolicies.ProductRead, enumPolicies.ProductEdit, enumPolicies.ProductWrite, enumPolicies.ProductPriceRead]}>
                <SidebarMenuItemWithSub
                    to='/catalog-management'
                    icon='basket'
                    title={intl.formatMessage({id: 'CATALOG.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/catalog-management/products' title={intl.formatMessage({id: 'CATALOG.MANAGEMENT.PRODUCT_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/catalog-management/suppliers' title={intl.formatMessage({id: 'CATALOG.MANAGEMENT.SUPPLIER_TITLE'})} hasBullet={true}/>
                </SidebarMenuItemWithSub>
            </PolicyChecker>


            <PolicyChecker policies={[enumPolicies.PriceRead, enumPolicies.PriceEdit]}>
                <SidebarMenuItemWithSub
                    to='/price-management'
                    icon='euro'
                    title={intl.formatMessage({id: 'PRICE.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/price-management/veranda' title={intl.formatMessage({id: 'PRICE.MANAGEMENT.VERANDA_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/price-management/unit' title={intl.formatMessage({id: 'PRICE.MANAGEMENT.UNIT_TITLE'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>
            </PolicyChecker>

            <PolicyChecker policies={[enumPolicies.StockRead, enumPolicies.StockEdit, enumPolicies.StockWrite]}>
                <SidebarMenuItemWithSub
                    to='/stock-management'
                    icon='dropbox'
                    title={intl.formatMessage({id: 'STOCK.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/stock-management/stocks' title={intl.formatMessage({id: 'STOCK.MANAGEMENT.STOCKS_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/stock-management/stocks-operation' title={intl.formatMessage({id: 'STOCK.MANAGEMENT.ADD_DROP_STOCKS_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/stock-management/stocks-print' title={intl.formatMessage({id: 'STOCK.MANAGEMENT.PRINT_TITLE'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>
            </PolicyChecker>

            <PolicyChecker policies={[enumPolicies.AdsLeadEdit]}>
                <SidebarMenuItemWithSub
                    to='/lead-management'
                    icon='dropbox'
                    title={intl.formatMessage({id: 'LEAD.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/lead-management/leads' title={intl.formatMessage({id: 'LEAD.MANAGEMENT.LEADS'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>
            </PolicyChecker>


            <PolicyChecker policies={[enumPolicies.OrderCheckoutEdit, enumPolicies.OrderCheckoutRead, enumPolicies.OrderCheckoutWrite]}>

                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.CHECKOUT'})}</span>
                    </div>
                </div>

                <SidebarMenuItemWithSub
                    to='/checkout-management'
                    icon='delivery-2'
                    title={intl.formatMessage({id: 'CHECKOUT.MANAGEMENT.BREADCRUMB.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/checkout-management/checkout' title={intl.formatMessage({id: 'CHECKOUT.MANAGEMENT.BREADCRUMB.CHECKOUT_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/checkout-management/checkout-summary' title={intl.formatMessage({id: 'CHECKOUT.MANAGEMENT.BREADCRUMB.SUMMARY_TITLE'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>
            </PolicyChecker>

            <PolicyChecker policies={[enumPolicies.OrderRead, enumPolicies.OrderWrite, enumPolicies.OrderEdit, enumPolicies.InvoiceEdit, enumPolicies.InvoiceRead, enumPolicies.InvoiceWrite]}>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.ORDER'})}</span>
                    </div>
                </div>

                <SidebarMenuItemWithSub
                    to='/order-management'
                    icon='delivery-2'
                    title={intl.formatMessage({id: 'ORDER.MANAGEMENT.TITLE'})}
                    fontIcon='bi-layers'
                >
                <PolicyChecker policies={[enumPolicies.QuoteRead, enumPolicies.QuoteEdit, enumPolicies.QuoteWrite]}>
                    <SidebarMenuItem to='/order-management/quotes' title={intl.formatMessage({id: 'ORDER.MANAGEMENT.QUOTE_TITLE'})} hasBullet={true}/>
                </PolicyChecker>

                    <PolicyChecker policies={[enumPolicies.OrderRead, enumPolicies.OrderWrite, enumPolicies.OrderEdit]}>
                        <SidebarMenuItem to='/order-management/orders' title={intl.formatMessage({id: 'ORDER.MANAGEMENT.ORDER_TITLE'})} hasBullet={true}/>
                    </PolicyChecker>

                    <PolicyChecker policies={[enumPolicies.InvoiceEdit, enumPolicies.InvoiceRead, enumPolicies.InvoiceWrite]}>
                        <SidebarMenuItem to='/order-management/invoices' title={intl.formatMessage({id: 'ORDER.MANAGEMENT.INVOICE_TITLE'})} hasBullet={true}/>
                    </PolicyChecker>
                </SidebarMenuItemWithSub>
            </PolicyChecker>

            <PolicyChecker policies={[enumPolicies.DevSupportEdit, enumPolicies.DevSupportRead, enumPolicies.DevSupportWrite]}>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.HELP'})}</span>
                    </div>
                </div>

                <SidebarMenuItemWithSub
                    to='/dev-support'
                    icon='support-24'
                    title={intl.formatMessage({id: 'DEV.SUPPORT.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/dev-support/tickets' title={intl.formatMessage({id: 'DEV.SUPPORT.TICKETS_TITLE'})} hasBullet={true}/>
                </SidebarMenuItemWithSub>
            </PolicyChecker>

            <PolicyChecker policies={[enumPolicies.Settings]}>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
                    </div>
                </div>

                <SidebarMenuItemWithSub
                    to='/localization'
                    icon='support-24'
                    title={intl.formatMessage({id: 'LOCALIZATION.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/localization/contents' title={intl.formatMessage({id: 'LOCALIZATION.CONTENT_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/localization/resources' title={intl.formatMessage({id: 'LOCALIZATION.RESOURCES_TITLE'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>

                <SidebarMenuItemWithSub
                    to='/setting-management'
                    icon='gear'
                    title={intl.formatMessage({id: 'SETTING.TITLE'})}
                    fontIcon='bi-layers'
                >
                    <SidebarMenuItem to='/setting-management/company' title={intl.formatMessage({id: 'SETTING.COMPANY_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/setting-management/invoice' title={intl.formatMessage({id: 'SETTING.INVOICE_TITLE'})} hasBullet={true}/>
                    <SidebarMenuItem to='/setting-management/quotation' title={intl.formatMessage({id: 'SETTING.QUOTATION_TITLE'})} hasBullet={true}/>

                </SidebarMenuItemWithSub>


            </PolicyChecker>
        </>
    )
}

export {SidebarMenuMain}
