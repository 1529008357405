/* SETTING */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'SETTINGS/CLEAR_STATE',

    GET_COMPANY_SETTINGS: 'SETTINGS/GET_COMPANY_SETTINGS',
    GET_COMPANY_SETTINGS_SUCCESS : 'SETTINGS/GET_COMPANY_SETTINGS_SUCCESS',
    POST_COMPANY_SETTINGS: 'SETTINGS/POST_COMPANY_SETTINGS',
    POST_COMPANY_SETTINGS_SUCCESS: 'SETTINGS/POST_COMPANY_SETTINGS_SUCCESS',

    GET_INVOICE_SETTINGS: 'SETTINGS/GET_INVOICE_SETTINGS',
    GET_INVOICE_SETTINGS_SUCCESS : 'SETTINGS/GET_INVOICE_SETTINGS_SUCCESS',
    POST_INVOICE_SETTINGS: 'SETTINGS/POST_INVOICE_SETTINGS',
    POST_INVOICE_SETTINGS_SUCCESS: 'SETTINGS/POST_INVOICE_SETTINGS_SUCCESS',

    GET_QUOTATION_SETTINGS: 'SETTINGS/GET_QUOTATION_SETTINGS',
    GET_QUOTATION_SETTINGS_SUCCESS : 'SETTINGS/GET_QUOTATION_SETTINGS_SUCCESS',
    POST_QUOTATION_SETTINGS: 'SETTINGS/POST_QUOTATION_SETTINGS',
    POST_QUOTATION_SETTINGS_SUCCESS: 'SETTINGS/POST_QUOTATION_SETTINGS_SUCCESS',

    GET_LOOKUP_VALUES:'SETTINGS/GET_LOOKUP_VALUES'


});

export default actionTypes;