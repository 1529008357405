/* CUSTOMERS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'CUSTOMERS/CLEAR_STATE',

    GET_CUSTOMERS: 'CUSTOMERS/GET_CUSTOMERS',
    GET_CUSTOMERS_SUCCESS: 'CUSTOMERS/GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILED: 'CUSTOMERS/GET_CUSTOMERS_FAILED',

    PATCH_CUSTOMER_STATUS: 'CUSTOMERS/PATCH_CUSTOMER_STATUS',
    PATCH_CUSTOMER_STATUS_SUCCESS: 'CUSTOMERS/PATCH_CUSTOMER_STATUS_SUCCESS',
    PATCH_CUSTOMER_STATUS_FAILED: 'CUSTOMERS/PATCH_CUSTOMER_STATUS_FAILED',

    GET_CUSTOMER: 'CUSTOMERS/GET_CUSTOMER',
    GET_CUSTOMER_SUCCESS: 'CUSTOMERS/GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILED: 'CUSTOMERS/GET_CUSTOMER_FAILED',

    POST_CUSTOMER: 'CUSTOMERS/POST_CUSTOMER',
    POST_CUSTOMER_SUCCESS: 'CUSTOMERS/POST_CUSTOMER_SUCCESS',
    POST_CUSTOMER_FAILED: 'CUSTOMERS/POST_CUSTOMER_FAILED',

    PATCH_CUSTOMER: 'CUSTOMERS/PATCH_CUSTOMER',
    PATCH_CUSTOMER_SUCCESS: 'CUSTOMERS/PATCH_CUSTOMER_SUCCESS',
    PATCH_CUSTOMER_FAILED: 'CUSTOMERS/PATCH_CUSTOMER_FAILED',
});

export default actionTypes;