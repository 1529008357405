// @flow
import types from './invoiceConstants';
import { getAsync, postAsync, deleteAsync, patchAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getInvoices = (filter) => async dispatch => {

    dispatch({
        type: types.GET_INVOICES
    })

    var path = `/invoices?`;

    if (filter) {
        path += "keyword=" + filter.keyword + "&";
        path += "state=" + filter.state + "&";
        path += "page=" + filter.page;
    }

    const response = await getAsync(path);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_INVOICES_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_INVOICES_FAILED,
            payload: response,
        })
    }
}

export const getInvoice = (id) => async dispatch => {

    dispatch({
        type: types.GET_INVOICE
    })

    const response = await getAsync(`/invoices/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_INVOICE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_INVOICE_FAILED,
            payload: response,
        })
    }
}

export const patchInvoice = (id, request) => async dispatch => {

    dispatch({
        type: types.PATCH_INVOICE
    })

    const response = await patchAsync(`/invoices/${id}`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_INVOICE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_INVOICE_FAILED,
            payload: response,
        })
    }
}

export const postInvoice = (request) => async dispatch => {

    dispatch({
        type: types.POST_INVOICE
    })

    const response = await postAsync(`/invoices`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_INVOICE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_INVOICE_FAILED,
            payload: response,
        })
    }
}

export const putItems = (id, request) => async dispatch => {

    dispatch({
        type: types.PUT_INVOICE_ITEMS
    })

    const response = await putAsync(`/invoices/${id}/items`, request);

    if (response.isSuccess) {
        dispatch(getInvoice(id))
        dispatch({
            type: types.PUT_INVOICE_ITEMS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PUT_INVOICE_ITEMS_FAILED,
            payload: response,
        })
    }
}

export const getHistory = (id) => async dispatch => {
    dispatch({
        type: types.GET_HISTORY
    })

    const response = await getAsync(`/invoices/${id}/history`);

    dispatch({
        type: types.GET_HISTORY_SUCCESS,
        payload: response,
    })
}

export const postTransaction = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_TRANSACTION
    })

    const response = await postAsync(`/invoices/${id}/partial-payment`, request);

    if (response.isSuccess) {
        dispatch(getInvoice(id))
        dispatch({
            type: types.POST_TRANSACTION_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_TRANSACTION_FAILED,
        })
    }
}

export const postInvoiceEmail = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_INVOICE_EMAIL
    })

    const response = await postAsync(`/invoices/${id}/email`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_INVOICE_EMAIL_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_INVOICE_EMAIL_FAILED,
        })
    }
}


export const getTransaction = (id, transactionId) => async dispatch => {

    dispatch({
        type: types.GET_TRANSACTION
    })

    const response = await getAsync(`/invoices/${id}/partial-payment/${transactionId}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_TRANSACTION_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_TRANSACTION_FAILED,
        })
    }
}


export const patchTransaction = (id, transactionId,request) => async dispatch => {

    dispatch({
        type: types.PATCH_TRANSACTION
    })

    const response = await patchAsync(`/invoices/${id}/partial-payment/${transactionId}`,request);

    if (response.isSuccess) {
        dispatch(getHistory(id))
        dispatch({
            type: types.PATCH_TRANSACTION_SUCCESS
        })
    } else {
        dispatch({
            type: types.PATCH_TRANSACTION_FAILED,
        })
    }
}
