// @flow
import types from './settingConstants';
import {act} from "react-dom/test-utils";

const INIT_STATE = {
    company: null,
    invoice: null,
    quotation:null,
    lookups: [],
    loading: false,
    success: null,
    error: null
};

const Setting = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        //COMPANY
        case types.GET_COMPANY_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                company: action.payload.data,
                loading: false
            };
        case types.POST_COMPANY_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_COMPANY_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_COMPANY_SETTINGS_FAIL',
                loading: false
            };
        //COMPANY

        //INVOICE
        case types.GET_INVOICE_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_INVOICE_SETTINGS_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                loading: false
            };
        case types.POST_INVOICE_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_INVOICE_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_INVOICE_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_INVOICE_SETTINGS_FAIL',
                loading: false
            };
        //INVOICE

        //QUOTATION
        case types.GET_QUOTATION_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                quotation: action.payload.data,
                loading: false
            };
        case types.POST_QUOTATION_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_QUOTATION_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_QUOTATION_SETTINGS_FAIL',
                loading: false
            };
        //QUOTATION


        //LOOKUP
        case types.GET_LOOKUP_VALUES:
            return {
                ...state,
                lookups: action.payload.data,
                loading: false
            };
        //LOOKUP

        default:
            return state;
    }
};

export default Setting;
