// @flow
import types from './orderConstants';

const INIT_STATE = {
    orders: [],
    order: null,
    items: [],
    statusList: [],
    historyList: [],
    statusFilter: [],
    hasNextPage: false,
    images: [],
    uploadedImageId: null,
    users: [],
    loading: false,
    loading_items: false,
    success: null
};

const Order = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        //Get Orders
        case types.GET_ORDERS:
            return {
                ...state,
                loading: true
            };
        case types.GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.data.items,
                hasNextPage: action.payload.data.hasNextPage,
                loading: false,
                error: null
            };
        case types.GET_ORDERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Orders

        //Get Orders
        case types.GET_ORDER:
            return {
                ...state,
                loading: true
            };
        case types.GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload.data,
                loading: state.loading_items ? true : false,
                error: null
            };
        case types.GET_ORDER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Orders

        //Get Order Items
        case types.GET_ORDER_ITEMS:
            return {
                ...state,
                loading: true,
                loading_items: action.payload.loading_items
            };
        case types.GET_ORDER_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.payload.data.items,
                loading: false,
                loading_items: false,
                error: null
            };
        case types.GET_ORDER_ITEMS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                loading_items: false
            };
        //Get Order Items

        //patch order content status
        case types.PATCH_ORDER_CONTENT_STATUS:
            return {
                ...state,
                loading: true
            };
        case types.PATCH_ORDER_CONTENT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case types.PATCH_ORDER_CONTENT_STATUS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //patch order content status

        //put order content 
        case types.PUT_ORDER_CONTENT:
            return {
                ...state,
                loading: true
            };
        case types.PUT_ORDER_CONTENT_SUCCESS:
            return {
                ...state,
                success: "ORDER.CONTENT.CREATED.SUCESS",
                error: null
            };
        case types.PUT_ORDER_CONTENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //put order content 
        //put order content 
        case types.DELETE_ORDER_CONTENT:
            return {
                ...state,
                loading: true
            };
        case types.DELETE_ORDER_CONTENT_SUCCESS:
            return {
                ...state,
                success: "ORDER.CONTENT.DELETED.SUCESS",
                error: null
            };
        case types.DELETE_ORDER_CONTENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //put order content 
        //post order content 
        case types.POST_ORDER_CONTENT:
            return {
                ...state,
                loading: true
            };
        case types.POST_ORDER_CONTENT_SUCCESS:
            return {
                ...state,
                success: "ORDER.CONTENT.CREATED.SUCESS",
                error: null
            };
        case types.POST_ORDER_CONTENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //post order content 

        case types.GET_STATUS:
            return {
                ...state,
                statusList: action.payload.data && action.payload.data.items,
                error: null
            };

        case types.GET_HISTORY:
            return {
                ...state,
                historyList: action.payload.data,
                error: null
            };

        case types.GET_STATUS_FILTER:
            return {
                ...state,
                statusFilter: action.payload.data,
                error: null
            };

        case types.GET_IMAGES:
            return {
                ...state,
                images: action.payload.data,
                error: null
            };

        //post order status 
        case types.POST_STATUS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_STATUS_SUCCESS:
            return {
                ...state,
                success: types.POST_STATUS_SUCCESS,
                error: null
            };
        case types.POST_STATUS_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };
        //post order status 

        //image
        case types.POST_IMAGE:
            return {
                ...state,
                success: null,
                uploadedImageId: null,
                loading: true
            };
        case types.POST_IMAGE_SUCCESS:
            return {
                ...state,
                uploadedImageId: action.payload,
                success: types.POST_IMAGE_SUCCESS,
                loading: false,
                error: null
            };
        case types.POST_IMAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                uploadedImageId: null,
                loading: false
            };
        case types.DELETE_IMAGE:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.DELETE_IMAGE_SUCCESS:
            var list = [...state.images].filter(s => s.id != action.payload.id);
            return {
                ...state,
                loading: false,
                images: list,
                success: types.DELETE_IMAGE_SUCCESS,
                error: null
            };
        case types.DELETE_IMAGE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //image

        //user
        case types.GET_USERS:
            return {
                ...state,
                users: action.payload.isSuccess ? action.payload.data : [],
            };
        //user
        default:
            return state;
    }
};

export default Order;
