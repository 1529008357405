import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../../app/modules/auth'
import {Loader, enumPolicies, Pagination} from '../../../_metronic/helpers'
import Flatpickr from 'react-flatpickr'
import Moment from 'react-moment'
import moment from 'moment';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import {toast} from 'react-toastify';

import "flatpickr/dist/themes/light.css";

import {clearState, getOrders, getStatusFilter, getUserList} from './orderActions';


const Orders = (props) => {
    //inputs from url
    const intl = useIntl();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);
    const fp = useRef(null);

    const [date, setDate] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState(null);
    const [isPaid, setIsPaid] = useState(null);
    const [isApproved, setIsApproved] = useState(null);
    const [userId, setUserId] = useState(null);
    const [page, setPage] = useState(1);

    //inputs from redux
    const {
        orders,
        hasNextPage,
        success,
        statusFilter,
        users,
        loading
    } = props;

    //actions
    const {
        clearState,
        getOrders,
        getStatusFilter,
        getUserList
    } = props;

    useEffect(() => {
        getStatusFilter()
        if (currentUser && currentUser.policyIds && currentUser.policyIds.some(policy => policy === enumPolicies.OrderAdmin || policy === enumPolicies.OrderPersonnel)) {
            getUserList();
        }
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success])

    const ordersBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'ORDERS.BREADCRUMB.TITLE'}),
            path: '/order-management/orders',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    useEffect(() => {
        setPage(1)
        getOrders({
            date: date,
            keyword: keyword,
            status: status,
            isApproved: isApproved,
            isPaid: isPaid,
            userId: userId,
            page: 1
        })
    }, [date, keyword, status, isApproved, isPaid, userId])

    const onPreviousClick = () => {
        var x = page - 1 <= 1 ? 1 : page - 1;
        setPage(x)
        getOrders({
            date: date,
            keyword: keyword,
            status: status,
            isApproved: isApproved,
            isPaid: isPaid,
            userId: userId,
            page: x
        })
    }

    const onNextClick = () => {
        setPage(page + 1)
        getOrders({
            date: date,
            keyword: keyword,
            status: status,
            isApproved: isApproved,
            isPaid: isPaid,
            userId: userId,
            page: page + 1
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={ordersBreadcrumbs}>{intl.formatMessage({id: 'ORDERS.BREADCRUMB.DESCRIPTION'})}</PageTitle>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card card-flush">
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            <div className="card-title">
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search" className="form-control form-control-solid w-250px ps-12"
                                           placeholder={intl.formatMessage({id: 'ORDERS.FIELD.SEARCHORDER'})} onChange={(e) => setKeyword(e.target.value)}/>
                                </div>
                            </div>
                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

                                <div className="input-group input-group-solid w-200px">
                                    <Flatpickr ref={fp}
                                               onChange={([date]) => {
                                                   var x = moment(date).format('YYYY-MM-DD');
                                                   setDate(x);
                                               }}
                                               options={{
                                                   dateFormat: "d-m-Y"
                                               }}
                                               className='form-control '
                                               placeholder={intl.formatMessage({id: 'ORDERS.FIELD.SELECTDATE'})}
                                    />
                                    <span className="input-group-text " onClick={() => {
                                        if (!fp?.current?.flatpickr) return;
                                        fp.current.flatpickr.clear();
                                        setDate(null);
                                    }}><i className="bi bi-x fs-1x"></i></span>

                                </div>
                                <div className="w-100 mw-200px">
                                    <select className="form-select form-select-solid" onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">{intl.formatMessage({id: 'ORDERS.SELECT.STATUS'})}</option>
                                        {statusFilter && statusFilter.map((item, i) => {
                                            return (
                                                <option key={`status_optipn_${i}`} value={item.value}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="w-100 mw-200px">
                                    <select className="form-select form-select-solid" onChange={(e) => setIsPaid(e.target.value)}>
                                        <option value="">{intl.formatMessage({id: 'ORDERS.SELECT.PAYMENTSTATUS'})}</option>
                                        <option value="true">{intl.formatMessage({id: 'ORDERS.FIELD.PAID'})}</option>
                                        <option value="false">{intl.formatMessage({id: 'ORDERS.FIELD.PENDING'})}</option>
                                    </select>
                                </div>
                                <div className="w-100 mw-200px">
                                    <select className="form-select form-select-solid" onChange={(e) => setIsApproved(e.target.value)}>
                                        <option value="">{intl.formatMessage({id: 'ORDERS.SELECT.APPROVE'})}</option>
                                        <option value="true">{intl.formatMessage({id: 'ORDERS.FIELD.APPROVED'})}</option>
                                        <option value="false">{intl.formatMessage({id: 'ORDERS.FIELD.NOTAPPROVED'})}</option>
                                    </select>
                                </div>
                                <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                    <div className="w-100 mw-200px">
                                        <select className="form-select form-select-solid" onChange={(e) => setUserId(e.target.value)}>
                                            <option value="">{intl.formatMessage({id: 'ORDERS.SELECT.DEALER'})}</option>
                                            {users && users.map((item, i) => {
                                                return (
                                                    <option key={`user_option_${i}`} value={item.id}>{item && item.contact && item.contact.company && `${item?.contact?.company} | `}{item.name} {item.surname}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </PolicyChecker>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className='table-responsive'>
                                <table className="table align-middle table-row-dashed fs-6 gy-5 text-center" id="kt_ecommerce_sales_table">
                                    <thead>
                                    <tr className="text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                                        <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.ORDER'})}</th>

                                        <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                            <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.CUSTOMER'})}</th>
                                        </PolicyChecker>

                                        <th className="min-w-70px">{intl.formatMessage({id: 'ORDERS.FIELD.STATUS'})}</th>
                                        <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderDealer]}>
                                            <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.TOTAL'})}</th>
                                        </PolicyChecker>
                                        <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.WISHDATE'})}</th>
                                        <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.DATEADDED'})}</th>
                                        <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.DATEMODIFIED'})}</th>
                                        <th className="min-w-100px">{intl.formatMessage({id: 'ORDERS.FIELD.ACTIONS'})}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                    {
                                        orders && orders.map((item, index) => {
                                            return (
                                                <tr key={`order_${index}`}>

                                                    <td data-kt-ecommerce-order-filter="order_id">
                                                        <button onClick={() => navigate(`/order-management/orders/${item.id}`)}
                                                                className="btn btn-link text-gray-800 text-hover-primary fw-bold">{item.id}</button>
                                                    </td>

                                                    <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3 d-none d-md-block">
                                                                    <div className="symbol-label">
                                                                        <img src={item.user.avatarLink} alt={item.user.name} className="w-100"/>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <a className="text-gray-800 text-hover-primary mb-1">{item.user.contact && item.user.contact.company}</a>
                                                                    <span style={{wordBreak: 'break-all'}}>{item.user.name} {item.user.surname}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </PolicyChecker>

                                                    <td className=" pe-0" data-order="New">
                                                        <div className="badge badge-light-success">{item.orderStatusName}</div>
                                                    </td>
                                                    <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderDealer]}>
                                                        <td className=" pe-0">
                                                            <span className="fw-bold">€ {item.price}</span>
                                                        </td>
                                                    </PolicyChecker>
                                                    <td className="">
                                                        <span className="fw-bold">{moment(item.wishedDeliveryDate).format('DD MM YYYY')}</span>
                                                    </td>
                                                    <td className="">
                                                        <span className="fw-bold"><Moment>{item.createdOn}</Moment></span>
                                                    </td>
                                                    <td className="">
                                                        <span className="fw-bold"><Moment>{item.modifiedOn}</Moment></span>
                                                    </td>
                                                    <td className="">
                                                        <button onClick={() => navigate(`/order-management/orders/${item.id}`)}
                                                                className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary">{intl.formatMessage({id: 'ORDERS.FIELD.DETAIL'})}<i
                                                            className="ki-duotone ki-right fs-5 ms-1"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <Pagination page={page} hasNextPage={hasNextPage} onPreviousClick={() => onPreviousClick()} onNextClick={() => onNextClick()}></Pagination>
                        </div>
                    </div>
                </div>
            </div>

            {(loading || inLoading) && <Loader/>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        orders: state.Order.orders,
        hasNextPage: state.Order.hasNextPage,
        statusFilter: state.Order.statusFilter,
        users: state.Order.users,
        loading: state.Order.loading,
        success: state.Order.success
    };
};

const mapActionsToProps = {
    clearState, getOrders, getStatusFilter, getUserList
}

export default connect(mapStateToProps, mapActionsToProps)(Orders)