import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader, enumPolicies, enumCustomerSourceType } from '../../../_metronic/helpers'
import Moment from 'react-moment';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';

import { clearState, changeStatus, getCustomerList } from './customerActions';

const Customers = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        customerList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getCustomerList
    } = props;


    const [keyword, setKeyword] = useState();
    const [isActive, setIsActive] = useState('');

    useEffect(() => {

        getCustomerList(keyword);

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [customerList])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    useEffect(() => {
        if (keyword != undefined) {
            const timeOutId = setTimeout(() => getCustomerList(keyword), 400);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const customersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CUSTOMER.MANAGEMENT.TITLE' }),
            path: '/customer-management/customers',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        setKeyword(value);
    }

    const onStatusChange = (id, isActive) => {
        changeStatus(id, isActive);
    }

    const resetData = () => {
        setIsActive('')
        getCustomerList(keyword);
    }

    const filterData = () => {
        getCustomerList(keyword, isActive);
    }

    return (
        <>

            <PageTitle breadcrumbs={customersBreadcrumbs} >{intl.formatMessage({ id: 'CUSTOMER.LIST.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'CUSTOMER.LIST.SEARCH.CUSTOMER' })} value={keyword} onChange={(e) => onSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="card-toolbar">
                        {/* begin::SubMenu */}
                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                            {/* begin::Header */}
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({ id: 'CUSTOMER.LIST.SEARCH.FILTER' })}</div>
                            </div>
                            {/* end::Header */}

                            {/* begin::Separator */}
                            <div className='separator border-gray-200'></div>
                            {/* end::Separator */}

                            {/* begin::Content */}
                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                {/* begin::Input group */}
                                <div className='mb-10'>
                                    <label className='form-label fs-6 fw-bold'>{intl.formatMessage({ id: 'USER.STATUS' })}:</label>
                                    <select
                                        className='form-select form-select-solid fw-bolder'
                                        data-kt-select2='true'
                                        data-placeholder='Select option'
                                        data-allow-clear='true'
                                        data-kt-user-table-filter='role'
                                        data-hide-search='true'
                                        onChange={(e) => setIsActive(e.target.value)}
                                        value={isActive}
                                    >
                                        <option value=''>{intl.formatMessage({ id: 'USER.TYPES.ALL' })}</option>
                                        <option value='true'>{intl.formatMessage({ id: 'USER.ACTIVE.ENABLED' })}</option>
                                        <option value='false'>{intl.formatMessage({ id: 'USER.ACTIVE.LOCKED' })}</option>
                                    </select>
                                </div>
                                {/* end::Input group */}

                                {/* begin::Actions */}
                                <div className='d-flex justify-content-end'>
                                    <button
                                        type='button'
                                        onClick={resetData}
                                        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='reset'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.RESET' })}
                                    </button>
                                    <button
                                        type='button'
                                        onClick={filterData}
                                        className='btn btn-primary fw-bold px-6'
                                        data-kt-menu-dismiss='true'
                                        data-kt-user-table-filter='filter'
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.APPLY' })}
                                    </button>
                                </div>
                                {/* end::Actions */}
                            </div>
                            {/* end::Content */}
                        </div>

                        <PolicyChecker policies={[enumPolicies.CustomerWrite]}>
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <Link to={`/customer-management/customers/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'CUSTOMER.LIST.ADD.CUSTOMER' })}</Link>
                            </div>
                        </PolicyChecker>

                    </div>
                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'CUSTOMER.NAME' })}</th>
                                <th>{intl.formatMessage({ id: 'CUSTOMER.PHONE' })}</th>
                                <th>{intl.formatMessage({ id: 'CUSTOMER.ADDRESS' })}</th>
                                <th>{intl.formatMessage({ id: 'CUSTOMER.SOURCE' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                customerList && customerList.items && customerList.items.map((customer, index) => {
                                    return (
                                        (
                                            <tr key={index}>
                                                <td>
                                                    <Link to={`/customer-management/customers/${customer.id}`}>{customer.id}</Link>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex flex-column">
                                                            <a className="text-gray-800 text-hover-primary mb-1">{customer.name} {customer.surname}</a>
                                                            <span style={{ wordBreak: 'break-all', minWidth: '185px' }}>{customer.email}</span>
                                                        </div>
                                                    </div>
                                                </td>


                                                <td>
                                                    <div className='form-check form-check-custom form-check-solid'>
                                                        {customer && customer.phone}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='form-check form-check-custom form-check-solid'>
                                                        {customer && customer.address}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='form-check form-check-custom form-check-solid'>
                                                        {customer && customer.customerSource && 
                                                        (customer.customerSource === enumCustomerSourceType.Manual ? `${intl.formatMessage({ id: 'CUSTOMER.SOURCE_MANUAL' })}` : `${intl.formatMessage({ id: 'CUSTOMER.SOURCE_LEAD' })}`)}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        customerList: state.Customer.customerList,
        loading: state.Customer.loading,
        success: state.Customer.success
    };
};

const mapActionsToProps = {
    clearState, changeStatus, getCustomerList
}

export default connect(mapStateToProps, mapActionsToProps)(Customers)