// @flow
import types from './leadConstants';
import { getAsync, patchAsync, postAsync, putAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getLeads = (type) => async dispatch => {

    dispatch({
        type: types.GET_LEADS
    })

    const response = await getAsync("/leads?campaignId=" + type);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_LEADS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_LEADS_FAILED,
            payload: response,
        })
    }
}

export const getLead = (id) => async dispatch => {

    dispatch({
        type: types.GET_LEAD
    })

    const response = await getAsync("/leads/" + id);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_LEAD_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_LEAD_FAILED,
            payload: response,
        })
    }
}

export const getCampaigns = () => async dispatch => {

    dispatch({
        type: types.GET_CAMPAIGNS
    })

    const response = await getAsync("/leads/campaigns");

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CAMPAIGNS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CAMPAIGNS_FAILED,
            payload: response,
        })
    }
}

export const patchLead = (model) => async dispatch => {

    dispatch({
        type: types.PATCH_LEAD
    })

    const response = await patchAsync("/leads/" + model.id, model);

    if (response.isSuccess) {
        const leadsResponse = await getAsync("/leads?id=" + model.id);
        dispatch({
            payload: leadsResponse,
            type: types.PATCH_LEAD_SUCCESS,
        })
    } else {
        dispatch({
            type: types.PATCH_LEAD_FAILED,
        })
    }
}

export const getUserList = () => async dispatch => {

    const response = await getAsync(`/users?type=-1&isActive=1&isApprove=1`);

    dispatch({
        type: types.GET_USERS,
        payload: response,
    })
}

export const getHistory = (id) => async dispatch => {
    var response = await getAsync(`/leads/${id}/history`);
    dispatch({
        type: types.GET_HISTORY,
        payload: response,
    })
}

export const postComment = (model) => async dispatch => {

    dispatch({
        type: types.POST_COMMENT
    })

    const response = await postAsync("/leads/" + model.id + "/comments", model);
    if (response.isSuccess) {
        dispatch({
            payload: response.data,
            type: types.POST_COMMENT_SUCCESS,
        })
    } else {
        dispatch({
            type: types.POST_COMMENT_FAILED,
        })
    }
}

export const postConvertToCustomer = (leadId) => async dispatch => {

    dispatch({
        type: types.POST_CONVERT_TO_CUSTOMER
    })

    const response = await postAsync("/leads/" + leadId + "/convert-to-customer");
    if (response.isSuccess) {
        dispatch({
            payload: response.data,
            type: types.POST_CONVERT_TO_CUSTOMER_SUCCESS,
        })
    } else {
        dispatch({
            type: types.POST_CONVERT_TO_CUSTOMER_FAILED,
        })
    }
}