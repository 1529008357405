import { useEffect, useState } from 'react';

const Pagination = (props) => {

    const {
        page,
        hasNextPage,
        onPreviousClick,
        onNextClick,
      } = props

    return (
        <>
            <ul className="pagination">
                <li className={`page-item previous ${page <= 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPreviousClick()}>
                        <i className="previous"></i>
                    </button>
                </li>
                <li className={`page-item next ${hasNextPage ? '' : 'disabled'}`} >
                    <button className="page-link" onClick={() => onNextClick()}>
                        <i className="next"></i>
                    </button>
                </li>
            </ul>

        </>
    );
}

export { Pagination };