// LanguageSelector.tsx
import React, { useState } from 'react';

interface Language {
  lang: string;
  name: string;
  flag: string;
}

interface LanguageSelectorProps {
  languages: Language[];
  currentLanguageCode?: string;
  onLanguageChange?: (language: Language) => void;
  className?: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  languages = [],
  currentLanguageCode = 'en',
  onLanguageChange,
  className = ''
}) => {
  const currentLanguage = languages.find(x => x.lang === currentLanguageCode);

  const handleLanguageChange = (language: Language) => {
    if (onLanguageChange) {
      onLanguageChange(language);
    }
  };

  return (
    <div className={`me-10 ${className}`}>
      <button 
        className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base" 
        data-kt-menu-trigger="click" 
        data-kt-menu-placement="bottom-start"
      >
        <img 
          data-kt-element="current-lang-flag" 
          className="w-20px h-20px rounded me-3" 
          src={currentLanguage?.flag} 
          alt=""
        />
        <span data-kt-element="current-lang-name" className="me-1">
          {currentLanguage?.name}
        </span>
        <span className="d-flex flex-center rotate-180">
          <i className="ki-duotone ki-down fs-5 text-muted m-0"></i>
        </span>
      </button>

      <div 
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7" 
        data-kt-menu="true" 
        id="kt_auth_lang_menu"
      >
        {languages.map((item, index) => (
          <div key={`lang_${index}`} className="menu-item px-3">
            <a 
              href="#" 
              className="menu-link d-flex px-5" 
              data-kt-lang={item.name}
              onClick={(e) => {
                e.preventDefault();
                handleLanguageChange(item);
              }}
            >
              <span className="symbol symbol-20px me-4">
                <img 
                  data-kt-element="lang-flag" 
                  className="rounded-1" 
                  src={item.flag} 
                  alt="" 
                />
              </span>
              <span data-kt-element="lang-name">{item.name}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;