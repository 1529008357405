/* ORDER */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'ORDER/CLEAR_STATE',

    GET_ORDERS: 'ORDER/GET_ORDERS',
    GET_ORDERS_SUCCESS: 'ORDER/GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILED: 'ORDER/GET_ORDERS_FAILED',

    GET_ORDER: 'ORDER/GET_ORDER',
    GET_ORDER_SUCCESS: 'ORDER/GET_ORDER_SUCCESS',
    GET_ORDER_FAILED: 'ORDER/GET_ORDER_FAILED',

    GET_ORDER_ITEMS: 'ORDER/GET_ORDER_ITEMS',
    GET_ORDER_ITEMS_SUCCESS: 'ORDER/GET_ORDER_ITEMS_SUCCESS',
    GET_ORDER_ITEMS_FAILED: 'ORDER/GET_ORDER_ITEMS_FAILED',

    PATCH_ORDER_CONTENT_STATUS: 'ORDER/PATCH_ORDER_CONTENT_STATUS',
    PATCH_ORDER_CONTENT_STATUS_SUCCESS: 'ORDER/PATCH_ORDER_CONTENT_STATUS_SUCCESS',
    PATCH_ORDER_CONTENT_STATUS_FAILED: 'ORDER/PATCH_ORDER_CONTENT_STATUS_FAILED',

    POST_ORDER_CONTENT: 'ORDER/POST_ORDER_CONTENT',
    POST_ORDER_CONTENT_SUCCESS: 'ORDER/POST_ORDER_CONTENT_SUCCESS',
    POST_ORDER_CONTENT_FAILED: 'ORDER/POST_ORDER_CONTENT_FAILED',

    PUT_ORDER_CONTENT: 'ORDER/PUT_ORDER_CONTENT',
    PUT_ORDER_CONTENT_SUCCESS: 'ORDER/PUT_ORDER_CONTENT_SUCCESS',
    PUT_ORDER_CONTENT_FAILED: 'ORDER/PUT_ORDER_CONTENT_FAILED',

    DELETE_ORDER_CONTENT: 'ORDER/DELETE_ORDER_CONTENT',
    DELETE_ORDER_CONTENT_SUCCESS: 'ORDER/DELETE_ORDER_CONTENT_SUCCESS',
    DELETE_ORDER_CONTENT_FAILED: 'ORDER/DELETE_ORDER_CONTENT_FAILED',

    GET_STATUS: 'ORDER/GET_STATUS',
    GET_HISTORY: 'ORDER/GET_HISTORY',
    GET_STATUS_FILTER: 'ORDER/GET_STATUS_FILTER',
    GET_IMAGES: 'ORDER/GET_IMAGES',

    POST_STATUS: 'ORDER/POST_STATUS',
    POST_STATUS_SUCCESS: 'ORDER/POST_STATUS_SUCCESS',
    POST_STATUS_FAILED: 'ORDER/POST_STATUS_FAILED',

    POST_IMAGE: 'ORDER/POST_IMAGE',
    POST_IMAGE_SUCCESS: 'ORDER/POST_IMAGE_SUCCESS',
    POST_IMAGE_FAILED: 'ORDER/POST_IMAGE_FAILED',

    DELETE_IMAGE: 'ORDER/DELETE_IMAGE',
    DELETE_IMAGE_SUCCESS: 'ORDER/DELETE_IMAGE_SUCCESS',
    DELETE_IMAGE_FAILED: 'ORDER/DELETE_IMAGE_FAILED',

    GET_USERS: 'ORDER/GET_USERS'
});

export default actionTypes;