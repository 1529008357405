import React, { useEffect, useState, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { useNavigate, useParams, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Loader, enumPolicies } from '../../../_metronic/helpers'
import { Button, Row, Col, CardBody, Card } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Moment from 'react-moment'
import moment from 'moment';
import { Audit } from '../../../_metronic/helpers/components/Audit';
import { Modal } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Dropzone from 'react-dropzone';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import Flatpickr from 'react-flatpickr'
import OrderPrint from './orderPrint';
import OrderComponentPrint from './orderComponentPrint';

import "flatpickr/dist/themes/light.css";

import { getProductList } from '../catalog-management/product/productActions';
import { clearState, getOrder, putOrder, getOrderItems, patchOrderContentStatus, putOrderContent, deleteOrderContent, postOrderContent, getStatus, postStatus, getHistory, getImages, uploadImage, deleteImage } from './orderActions';
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';


const Order = (props) => {
    //inputs from url
    const param = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const fp = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [newContent, setNewContent] = useState({});
    const [variantList, setVariantList] = useState([]);
    const [plannedDeliveryDate, setPlannedDeliveryDate] = useState(null);
    const [triggerStatus, setTriggerStatus] = useState('');
    const [myFiles, setMyFileList] = useState([])
    const [counter, setCounter] = useState(0)

    //inputs from redux
    const {
        order,
        items,
        productList,
        success,
        loading,
        modalLoading,
        statusList,
        historyList,
        images,
        uploadedImageId
    } = props;

    //actions
    const {
        clearState,
        getOrder,
        putOrder,
        getOrderItems,
        patchOrderContentStatus,
        getProductList,
        putOrderContent,
        deleteOrderContent,
        postOrderContent,
        getStatus,
        postStatus,
        getHistory,
        getImages,
        uploadImage,
        deleteImage
    } = props;

    useEffect(() => {
        getOrder(param.id)
        getOrderItems(param.id);
        getStatus(param.id);
        getHistory(param.id);
        getImages(param.id)
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    useEffect(() => {
        if (uploadedImageId) {
            onDeleteFile(uploadedImageId);
        }
    }, [uploadedImageId])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'ORDERS.BREADCRUMB.TITLE' }),
            path: '/order-management/orders',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onDropRejected = () => {
        toast.error(intl.formatMessage({ id: 'GENERAL.FILE.INVALID' }));
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const handleAcceptedFiles = async (filesRaw) => {

        let files = []
        for (let i = 0; i < filesRaw.length; i++) {
            let f = filesRaw[i];

            var image = await resizeFile(f);
            image.name = f.name;

            var counterr = counter + 1;
            setCounter(counterr);

            files.push({
                id: image.name + "-" + counterr,
                file: image,
                name: image.name,
                actionType: 6,
                extension: image.type.split('/')[1],
            })
        }

        files.map(item =>
            Object.assign(item.file, {
                preview: item.file['type'].split('/')[0] === 'image' ? URL.createObjectURL(item.file) : null
            })
        );
        setMyFileList(files)
    };

    const onSaveFile = (id) => {
        var file = myFiles.find(s => s.id == id);
        uploadImage(param.id, file);
    }

    const onDeleteExistFile = (id) => {
        deleteImage(param.id, id)
    }

    const onDeleteFile = (id) => {
        var files = [...myFiles].filter(s => s.id != id);
        setMyFileList(files);
    }

    const onContentCheckChange = (item) => {
        patchOrderContentStatus(param.id, item.id)
        item.checked = !item.checked;
    }

    const openContentModal = (item) => {
        getProductList();
        setNewContent({ orderItemId: item.id, orderId: item.orderId, quantity: 1 })
        setShowModal(true);
    }

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const onPrintContent = (item, content) => {

        const markup = renderToStaticMarkup(
            <OrderComponentPrint
                order={order}
                item={item}
                content={content}
                intl={intl}
            />
        );

        const newTab = window.open('', '_blank');
        newTab.document.write(markup);
        newTab.print();

    }

    const onPrint = () => {

        const markup = renderToStaticMarkup(
            <OrderPrint
                order={order}
                items={items}
                intl={intl}
            />
        );

        const newTab = window.open('', '_blank');
        newTab.document.write(markup);
        newTab.print();

    }

    const handleValidSubmit = (event, values) => {
        var content = {
            orderItemId: newContent.orderItemId,
            orderId: newContent.orderId,
            productId: values.productId,
            variantId: values.variantId,
            quantity: values.quantity,
            additionalAttributesJson: JSON.stringify({
                FreeText: values.description
            })
        };
        putOrderContent(param.id, content);
        onModalClose();
    }

    const onProductChange = (e) => {
        setVariantList([]);
        var productId = e.target.value;
        if (productId) {
            var product = productList.find(s => s.id == productId);
            if (product && product.variants) {
                setVariantList(product.variants);
            }
        }
    }

    const onDeleteOrderContent = (id) => {

        Swal.fire({
            title: intl.formatMessage({ id: 'ORDER.QUESTION.SURE' }),
            text: intl.formatMessage({ id: 'ORDER.QUESTION.CONTENT.DELETE' }),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            cancelButtonColor: "#dbdfe9",
            confirmButtonText: intl.formatMessage({ id: "ORDER.ANSWER.CONTENT.YES" })
        }).then((result) => {
            if (result.isConfirmed) {
                deleteOrderContent(param.id, id)
            }
        });
    }

    const onModalClose = () => {
        setShowModal(false);
        setNewContent({});
    }

    const recalculateContents = (orderItemId) => {
        Swal.fire({
            title: intl.formatMessage({ id: 'ORDER.QUESTION.SURE' }),
            text: intl.formatMessage({ id: "ORDER.QUESTION.CONTENT.CALCULATE" }),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            cancelButtonColor: "#dbdfe9",
        }).then((result) => {
            if (result.isConfirmed) {
                postOrderContent(param.id, orderItemId)
            }
        });
    }

    if (!order) {
        return (
            <><Card>
                <CardBody className='text-center'>
                    <i className="bi bi-box fs-5hx text-primary"></i>
                    <Row className='mt-10'></Row>
                    <h1>{intl.formatMessage({ id: "ORDER.FIELD.NOTFOUND" })}</h1>
                    <Row>
                        <Col md={4}>
                        </Col>
                        <Col md={4}>
                            <button onClick={() => navigate("/order-management/orders")} type='submit' className='btn btn-primary mt-10'><span className='fs-2'>{intl.formatMessage({ id: "ORDER.FIELD.GOTOORDERS" })} </span></button>
                        </Col>
                        <Col md={4}>
                        </Col>
                    </Row>
                </CardBody>
            </Card></>
        )
    }

    const onPlannedDeliveryDateClick = ([date]) => {
        if (date) {
            Swal.fire({
                title: intl.formatMessage({ id: "ORDER.QUESTION.SURE" }),
                text: intl.formatMessage({ id: "ORDER.QUESTION.PLANNED.DATE" }),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: PRIMARY_COLOR,
                cancelButtonColor: "#dbdfe9",
            }).then((result) => {
                if (result.isConfirmed) {
                    var x = moment(date).format('YYYY-MM-DD');
                    setPlannedDeliveryDate(x);
                    putOrder(param.id, { plannedDeliveryDate: x })
                }
                else {
                    if (!fp?.current?.flatpickr) return;
                    fp.current.flatpickr.clear(); setPlannedDeliveryDate(null);
                }
            });
        }
    }

    const onStatusChange = (value) => {
        if (value) {
            setTriggerStatus(value)
            Swal.fire({
                title: intl.formatMessage({ id: "ORDER.QUESTION.SURE" }),
                text: intl.formatMessage({ id: "ORDER.QUESTION.STATUS" }),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: PRIMARY_COLOR,
                cancelButtonColor: "#dbdfe9",
                confirmButtonText: intl.formatMessage({ id: "GENERAL.YES" })
            }).then((result) => {
                if (result.isConfirmed) {
                    postStatus(param.id, value)
                } else {
                    setTriggerStatus('')
                }
            });
        }
    }

    const historyValue = (value) => {

        if (moment(value).isValid() && moment(value).year() > 2010) {
            return <Moment>{value}</Moment>
        }

        return value;
    }

    const renderStatusList = () => {
        if (statusList && statusList.length > 0) {
            return (
                <tr>
                    <td className="text-muted">
                        <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-calendar fs-2 me-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>{intl.formatMessage({ id: "ORDER.FIELD.ORDERACTION" })}</div>
                    </td>
                    <td className="fw-bold text-end">
                        <select className='form-control form-select-sm form-select form-control-solid' onChange={(e) => onStatusChange(e.target.value)} value={triggerStatus}>
                            <option value={''}>Select</option>
                            {statusList.map((item) => {
                                return (
                                    <option key={`status_dropdown_${item.triggerId}`} value={item.triggerId}>{item.triggerName}</option>
                                )
                            })}
                        </select>
                    </td>
                </tr>
            )
        }

    }

    const renderContentImageLink = (content) => {
        if (content && content.imageLink) {
            return (
                <div className="symbol symbol-circle symbol-100px overflow-hidden me-3">
                    <div className="symbol-label">
                        <img src={content.imageLink} alt={content.imageLink} className="w-100" />
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderContentItemName = (item, content) => {

        if (content && content.productId > 0) {

            return (
                <>
                    <div className="d-flex flex-column ">
                        {renderContentImageLink(content)}
                        <a target='_blank' href={`/catalog-management/products/${content.productId}/detail`} className="text-gray-800 text-hover-primary mb-1 fw-bold">{content.productName} - <span className='fw-light'>{content.productCode}</span></a>
                        <span className='fw-bold'>{content.variantName} - <span className='fw-light'>{content.variantCode}</span></span>
                    </div>
                    <PolicyChecker policies={[enumPolicies.OrderAdmin]}>
                        <br></br>
                        <button className='btn btn-sm btn-light-danger mt-5' onClick={() => onDeleteOrderContent(content.id)}>{intl.formatMessage({ id: "ORDER.FIELD.CONTENTDELETE" })}</button>
                    </PolicyChecker>
                </>
            )
        }

        return (
            <>
                {item.name}
            </>
        )

    }

    const renderContentModal = () => {
        return (
            <Modal size="xl" centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({ id: "ORDER.FIELD.ADDCONTENT" })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <CardBody>
                            <AvForm key={1} onValidSubmit={handleValidSubmit}>
                                <Row>
                                    <Col>
                                        <AvField type="select" name="productId" label={intl.formatMessage({ id: "ORDER.FIELD.PRODUCT" })} validate={validation} value={newContent && newContent.productId} onChange={(e) => onProductChange(e)}>
                                            <option value=''>{intl.formatMessage({ id: "GENERAL.SELECT" })}</option>
                                            {productList && productList.map((item, index) => {
                                                return (
                                                    <option key={`products_${index}`} value={item.id}>{item.name} ({item.sku})</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                    <Col>
                                        <AvField type="select" name="variantId" label={intl.formatMessage({ id: "ORDER.FIELD.VARIANT" })} validate={validation} value={newContent && newContent.variantId}>
                                            <option value=''>{intl.formatMessage({ id: "GENERAL.SELECT" })}</option>
                                            {variantList.map((item, index) => {
                                                return (
                                                    <option key={`variants_${index}`} value={item.id}>{item.name} ({item.code})</option>
                                                )
                                            })}
                                        </AvField>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AvField name="quantity" label={intl.formatMessage({ id: "ORDER.FIELD.QUANTITY" })} type="number" min={1} value={newContent && newContent.quantity} validate={validation} />
                                    </Col>
                                    <Col>
                                        <AvField name="description" label={intl.formatMessage({ id: "ORDER.FIELD.DESCRIPTION" })} type="textarea" rows={4} value={newContent && newContent.description} validate={validation} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Button color="success" type="submit">{intl.formatMessage({ id: "GENERAL.SUBMIT" })}</Button>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" onClick={() => onModalClose()}>{intl.formatMessage({ id: "GENERAL.CLOSE" })}</button>
                </Modal.Footer>
                {(modalLoading || loading) && <Loader />}
            </Modal>
        )
    }

    const renderContentItem = (item) => {
        if (item && item.contents && item.contents.length > 0) {
            return (
                <tr key={`item_content_${item.id}`}>
                    <td colSpan={8}>
                        <table className="table table-row-dashed table-row-gray-500 gy-5 gs-5 mb-0">
                            <thead>
                                <tr className="fw-bolder fs-5 text-gray-800 border-bottom border-gray-800">
                                    <th>{intl.formatMessage({ id: "ORDER.FIELD.PRODUCTNAME" })}</th>
                                    <th>{intl.formatMessage({ id: "ORDER.FIELD.QUANTITY" })}</th>
                                    <th>{intl.formatMessage({ id: "ORDER.FIELD.DETAIL" })}</th>
                                    <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                        <th>{intl.formatMessage({ id: "ORDER.FIELD.ACTION" })}</th>
                                        <th className='text-end'>{intl.formatMessage({ id: "ORDER.FIELD.ACTION" })}</th>
                                    </PolicyChecker>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    item.contents && item.contents.map((content) => {
                                        var detail = content.additionalAttributesJson != '' && JSON.parse(content.additionalAttributesJson);
                                        return (
                                            <tr key={`item_content_tr_${content.id}`} className='py-5 fw-semibold vertical-middle fs-6'>
                                                <td width={"20%"}>
                                                    {renderContentItemName(item, content)}
                                                </td>
                                                <td width={"5%"} style={{ textAlign: "-webkit-center" }}>{content.quantity}</td>
                                                <td width={"35%"}>
                                                    {detail.Width ? <p>{intl.formatMessage({ id: "ORDER.FIELD.WIDTH" })}: {detail.Width}</p> : null}
                                                    {detail.Depth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.DEPTH" })}: {detail.Depth}</p> : null}
                                                    {detail.Height ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HEIGHT" })}: {detail.Height}</p> : null}
                                                    {detail.FrameColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRAMECOLOR" })}: {detail.FrameColorName}</p> : null}
                                                    {detail.OtherFrameColor ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGRAMECOLOR" })}: {detail.OtherFrameColor}</p> : null}
                                                    {detail.Length ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LENGTH" })}: {detail.Length}</p> : null}
                                                    {detail.FrontHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRONTHEIGHT" })}: {detail.FrontHeight}</p> : null}
                                                    {detail.RearHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.REARHEIGHT" })}: {detail.RearHeight}</p> : null}
                                                    {detail.OrderTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ORDERTYPE" })}: {detail.OrderTypeName}</p> : null}
                                                    {detail.StandTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.STANDTYPE" })}: {detail.StandTypeName}</p> : null}
                                                    {detail.RoofTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ROOFTYPE" })}: {detail.RoofTypeName}, {detail.MaterialColorName}</p> : null}
                                                    {(detail.RoofTypeName === null && detail.MaterialColorName) ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MATERIALCOLOR" })}: {detail.MaterialColorName}</p> : null}
                                                    {detail.ModelTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MODELTYPE" })}: {detail.ModelTypeName}</p> : null}
                                                    {detail.LedTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDTYPE" })}: {detail.LedTypeName}</p> : null}
                                                    {detail.LedColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDCOLOR" })}: {detail.LedColorName}</p> : null}
                                                    {detail.NrOfLedPerColumn ? <p>{intl.formatMessage({ id: "ORDER.FIELD.NROFLED" })}: {detail.NrOfLedPerColumn}</p> : null}
                                                    {detail.MaterialTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MATERIALTYPE" })}: {detail.MaterialTypeName}</p> : null}
                                                    {detail.SideName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.SIDE" })}: {detail.SideName}</p> : null}
                                                    {detail.RailSetTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.RAILSET" })}: {detail.RailSetTypeName}</p> : null}
                                                    {detail.IsMeenemerAdded ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HASMEENEMER" })}: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                                                    {detail.GlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSWIDTH" })}: {detail.GlassWidth}</p> : null}
                                                    {detail.OtherGlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSWIDTH" })}: {detail.OtherGlassWidth}</p> : null}
                                                    {detail.GlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSHEIGHT" })}: {detail.GlassHeight}</p> : null}
                                                    {detail.OtherGlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSHEIGHT" })}: {detail.OtherGlassHeight}</p> : null}
                                                    {detail.FreeText ? <p>{intl.formatMessage({ id: "ORDER.FIELD.DESCRIPTION" })}: {detail.FreeText}</p> : null}
                                                </td>

                                                <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                                    <td width={"10%"}>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                            <input className="form-check-input" type="checkbox" checked={content.checked} onChange={(e) => onContentCheckChange(content)} />
                                                        </div>
                                                    </td>
                                                    <td width={"10%"}>
                                                        <Audit className="text-end" table="OrderContent" identifier={content.id} />
                                                        <div className="d-flex flex-end rounded-3 mt-5">
                                                            <i className={`ki-duotone ki-printer fs-3x`} onClick={() => onPrintContent(item, content)}>
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                                <span className="path4"></span>
                                                                <span className="path5"></span>
                                                            </i>
                                                        </div>
                                                    </td>
                                                </PolicyChecker>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
        }
        return;
    }

    const renderSummary = () => {
        return (
            <div className="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">

                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: "ORDER.FIELD.ITEMS" })} #{order.id}</h2>
                            </div>
                            <div className='card-toolbar'>
                                <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                    <div className="d-flex flex-end rounded-3 mt-5">
                                        <i className={`ki-duotone ki-printer fs-3x`} onClick={() => onPrint()}>
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                            <span className="path3"></span>
                                            <span className="path4"></span>
                                            <span className="path5"></span>
                                        </i>
                                    </div>
                                </PolicyChecker>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table table-rounded border gy-7 gs-7">
                                    <thead>
                                        <tr className="fw-bolder fs-5 text-gray-800 border-bottom border-gray-2000">
                                            <th>{intl.formatMessage({ id: "ORDER.FIELD.NAME" })}</th>
                                            <th>{intl.formatMessage({ id: "ORDER.FIELD.DETAIL" })}</th>
                                            <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderDealer]}>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.PRICE" })}</th>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.QUANTITY" })}</th>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.TOTALPRICE" })}</th>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.VAT" })} %</th>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.VAT" })} </th>
                                            </PolicyChecker>
                                            <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                                <th>{intl.formatMessage({ id: "ORDER.FIELD.ACTION" })}</th>
                                            </PolicyChecker>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items && items.map((item) => {
                                                var detail = JSON.parse(item.orderItemAttributesJson);
                                                return (
                                                    <>
                                                        <tr key={`item_tr_${item.id}`} className='bg-gray-100 py-5 fw-semibold vertical-middle border-bottom border-top border-gray-300 fs-6'>
                                                            <td width={"20%"}>
                                                                {item.name}
                                                                <PolicyChecker policies={[enumPolicies.OrderAdmin]}>
                                                                    <br></br>
                                                                    <button className='btn btn-sm btn-primary mt-10' onClick={() => openContentModal(item)}>{intl.formatMessage({ id: "ORDER.FIELD.ADDCONTENT" })}</button>
                                                                    <br></br>
                                                                    <button className='btn btn-sm btn-secondary mt-5' onClick={() => recalculateContents(item.id)}>{intl.formatMessage({ id: "ORDER.FIELD.RECALCULATECONTENTS" })}</button>
                                                                </PolicyChecker>
                                                            </td>
                                                            <td width={"25%"}>
                                                                {detail.Width ? <p>{intl.formatMessage({ id: "ORDER.FIELD.WIDTH" })}: {detail.Width}</p> : null}
                                                                {detail.Depth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.DEPTH" })}: {detail.Depth}</p> : null}
                                                                {detail.Height ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HEIGHT" })}: {detail.Height}</p> : null}
                                                                {detail.FrameColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRAMECOLOR" })}: {detail.FrameColorName}</p> : null}
                                                                {detail.OtherFrameColor ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGRAMECOLOR" })}: {detail.OtherFrameColor}</p> : null}
                                                                {detail.Length ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LENGTH" })}: {detail.Length}</p> : null}
                                                                {detail.FrontHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRONTHEIGHT" })}: {detail.FrontHeight}</p> : null}
                                                                {detail.RearHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.REARHEIGHT" })}: {detail.RearHeight}</p> : null}
                                                                {detail.OrderTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ORDERTYPE" })}: {detail.OrderTypeName}</p> : null}
                                                                {detail.StandTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.STANDTYPE" })}: {detail.StandTypeName}</p> : null}
                                                                {detail.RoofTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ROOFTYPE" })}: {detail.RoofTypeName}, {detail.MaterialColorName}</p> : null}
                                                                {(detail.RoofTypeName === null && detail.MaterialColorName) ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MATERIALCOLOR" })}: {detail.MaterialColorName}</p> : null}
                                                                {detail.ModelTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MODELTYPE" })}: {detail.ModelTypeName}</p> : null}
                                                                {detail.LedTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDTYPE" })}: {detail.LedTypeName}</p> : null}
                                                                {detail.LedColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDCOLOR" })}: {detail.LedColorName}</p> : null}
                                                                {detail.NrOfLedPerColumn ? <p>{intl.formatMessage({ id: "ORDER.FIELD.NROFLED" })}: {detail.NrOfLedPerColumn}</p> : null}
                                                                {detail.MaterialTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MATERIALTYPE" })}: {detail.MaterialTypeName}</p> : null}
                                                                {detail.SideName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.SIDE" })}: {detail.SideName}</p> : null}
                                                                {detail.RailSetTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.RAILSET" })}: {detail.RailSetTypeName}</p> : null}
                                                                {detail.IsMeenemerAdded ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HASMEENEMER" })}: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                                                                {detail.GlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSWIDTH" })}: {detail.GlassWidth}</p> : null}
                                                                {detail.OtherGlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSWIDTH" })}: {detail.OtherGlassWidth}</p> : null}
                                                                {detail.GlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSHEIGHT" })}: {detail.GlassHeight}</p> : null}
                                                                {detail.OtherGlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSHEIGHT" })}: {detail.OtherGlassHeight}</p> : null}
                                                            </td>
                                                            <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderDealer]}>
                                                                <td width={"10%"}>€ {item.price}</td>
                                                                <td width={"10%"}>{item.quantity}</td>
                                                                <td width={"10%"}>€ {item.totalPrice}</td>
                                                                <td width={"10%"}> {item.vat}%</td>
                                                                <td width={"10%"}>€ {item.vatPrice}</td>
                                                            </PolicyChecker>
                                                            <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                                                <td width={"5%"}>
                                                                    <Audit className="text-end" table="OrderItem" identifier={item.id} />
                                                                </td>
                                                            </PolicyChecker>
                                                        </tr >
                                                        {renderContentItem(item)}
                                                    </>
                                                )
                                            })
                                        }

                                        <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderDealer]}>
                                            <tr className="py-5 border-bottom border-top vertical-middle  fs-4 vertical-middle">
                                                <td colSpan={3}></td>
                                                <td colSpan={3}>{intl.formatMessage({ id: "ORDER.FIELD.SUBTOTALEXVAT" })} <br></br> 21% {intl.formatMessage({ id: "ORDER.FIELD.VAT" })} </td>
                                                <td>
                                                    € {items && items.map(s => s.subPrice).reduce((a, b) => a + b, 0).toFixed(2)}
                                                    <br></br>
                                                    € {items && items.map(s => s.vatPrice).reduce((a, b) => a + b, 0).toFixed(2)}
                                                </td>
                                                <td ></td>
                                            </tr>

                                            <tr className="py-5 fw-bold  border-bottom vertical-middle border-gray-500 fs-3 vertical-middle">
                                                <td colSpan={3}></td>
                                                <td colSpan={3}>{intl.formatMessage({ id: "ORDER.FIELD.TOTAL" })}</td>
                                                <td>€ {items && items.map(s => s.totalPrice).reduce((a, b) => a + b, 0)}</td>
                                                <td></td>
                                            </tr>
                                        </PolicyChecker>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }

    const renderImages = () => {

        return (
            <div className="tab-pane fade" id="kt_ecommerce_sales_order_images" role="tabpanel">
                <div className='card'>
                    <div className="card-body">

                        <Dropzone
                            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                            onDropRejected={onDropRejected}
                            accept={['image/png', 'image/jpg', 'image/jpeg']}
                            multiple={true}
                            maxSize={20000000}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <>
                                        <div className="dropzone dz-clickable">
                                            <div className="dz-message needsclick" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <i className="ki-duotone ki-file-up fs-3x text-primary"><span className="path1"></span><span className="path2"></span></i>
                                                <div className="ms-4">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">{intl.formatMessage({ id: 'ORDER.IMAGE.UPLOAD' })} </h3>
                                                    <span className="fs-7 fw-semibold text-primary opacity-75">{intl.formatMessage({ id: 'ORDER.IMAGE.MAX' })}</span>
                                                    <br></br>
                                                    <span className="fs-7 fw-semibold text-primary opacity-75">{intl.formatMessage({ id: 'ORDER.IMAGE.FORMATS' })}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }}
                        </Dropzone>

                        <div className={"dropzone-previews mt-3"} id="file-previews">
                            {myFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}>
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        width={250}
                                                        data-dz-thumbnail=""
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.file.name}
                                                        src={f.file.preview}
                                                    />
                                                </Col>
                                                <Col className="pl-0">
                                                    <Link to="#" className="text-muted font-weight-bold">
                                                        {f.file.name}
                                                    </Link>
                                                </Col>

                                                <Col className="col-auto">
                                                    <Button
                                                        className="btn btn-icon btn-circle btn-active-success"
                                                        onClick={() => onSaveFile(f.id)}>
                                                        <i className="ki-duotone ki-check-circle fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                        </i>
                                                    </Button>
                                                </Col>

                                                <Col className="col-auto">
                                                    <Button
                                                        className="btn btn-icon btn-circle btn-active-danger"
                                                        onClick={() => onDeleteFile(f.id)}>
                                                        <i className="ki-duotone ki-cross-circle fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>




                    </div>
                </div>

                <div className='card mt-20'>
                    <div className='card-header'>
                        <h3 className="card-title">{intl.formatMessage({ id: "ORDER.FIELD.IMAGES" })}</h3>
                    </div>
                    <div className='card-body'>
                        <div className='row'>

                            {images && images.map((item, index) => {
                                return (
                                    <div key={item.id} className='col-md-3'>
                                        <div className="card h-100 ">
                                            <Audit table="ProductImage" identifier={item.id} />
                                            <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                <a href={item.link} download={true} target="_blank" className="text-gray-800 text-hover-primary d-flex flex-column">
                                                    <div className="symbol symbol-150px mb-5">
                                                        <img src={item.link} alt="" />
                                                    </div>
                                                    <div className="fs-7 fw-semibold text-gray-500"><Moment>{item.createdOn}</Moment> </div>
                                                </a>


                                                <div className="fs-7 fw-semibold text-gray-500  mt-5">
                                                    <button type="button" className="btn btn-icon btn-flex btn-active-light-danger w-30px h-30px me-3" onClick={() => onDeleteExistFile(item.id)}>
                                                        <i className="ki-duotone ki-trash fs-3">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                            <span className="path4"></span>
                                                            <span className="path5"></span>
                                                        </i>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const renderDocuments = () => {
        if (order?.invoice) {
            return (
                <>
                    <div className="separator my-10"></div>
                    <div className="card-header">
                        <div className="card-title">
                            <h2>{intl.formatMessage({ id: "ORDER.FIELD.DOCUMENTS" })}</h2>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive">
                            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                <tbody className="fw-semibold text-gray-600">
                                    <tr>
                                        <td className="text-muted">
                                            <div className="d-flex align-items-center">
                                                <i className="ki-duotone ki-devices fs-2 me-2">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                    <span className="path3"></span>
                                                    <span className="path4"></span>
                                                    <span className="path5"></span>
                                                </i>
                                                {intl.formatMessage({ id: "ORDER.FIELD.INVOICE" })}
                                            </div>
                                        </td>
                                        <td className="fw-bold text-end">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button
                                                    onClick={() => navigate(`/order-management/invoices/${order.invoice.id}`)}
                                                    className="btn btn-link text-gray-600 text-hover-primary"
                                                >
                                                    #{order.invoice.invoiceNr ? order.invoice.invoiceNr : '-'}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            );
        }

        return null;
    }

    const renderHistory = () => {
        return (
            <div className="tab-pane fade" id="kt_ecommerce_sales_order_history" role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className="card card-flush py-4 flex-row-fluid">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({ id: "ORDER.FIELD.HISTORY" })}</h2>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-100px">{intl.formatMessage({ id: "ORDER.FIELD.DATE" })}</th>
                                            <th className="min-w-100px">{intl.formatMessage({ id: "ORDER.FIELD.USER" })}</th>
                                            <th className="min-w-175px">{intl.formatMessage({ id: "ORDER.FIELD.COMMENT" })}</th>
                                            <th className="min-w-70px">{intl.formatMessage({ id: "ORDER.FIELD.OLDVALUE" })}</th>
                                            <th className="min-w-100px">{intl.formatMessage({ id: "ORDER.FIELD.NEWVALUE" })}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">
                                        {historyList && historyList.map((history, i) => {
                                            return (
                                                <tr key={`history_item_${i}`}>
                                                    <td><Moment>{history.date}</Moment></td>
                                                    <td>{history.userName}</td>
                                                    <td>{history.fieldName}</td>
                                                    <td>
                                                        <div className="badge badge-light-info">{historyValue(history.oldValue)}</div>
                                                    </td>
                                                    <td>
                                                        <div className="badge badge-light-success">{historyValue(history.newValue)}</div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'ORDERS.BREADCRUMB.DESCRIPTION' })}</PageTitle>



            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                        <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto">
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_summary">{intl.formatMessage({ id: "ORDER.FIELD.SUMMARY" })}</a>
                                </li>
                                <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                    <li className="nav-item">
                                        <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_images">{intl.formatMessage({ id: "ORDER.FIELD.IMAGES" })}</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history">{intl.formatMessage({ id: "ORDER.FIELD.HISTORY" })}</a>
                                    </li>
                                </PolicyChecker>
                            </ul>
                        </div>
                        <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                            <div className="card card-flush py-4 flex-row-fluid">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>{intl.formatMessage({ id: "ORDER.FIELD.ORDERDETAILS" })} (#{order.id}{order.reference ? ` / ${order.reference}` : ""}) </h2>
                                    </div>
                                    <div className='card-actions'>
                                        <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                            <Audit className="text-end" table="Order" identifier={order.id} />
                                        </PolicyChecker>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="table-responsive">
                                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                            <tbody className="fw-semibold text-gray-600">
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-calendar fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.CREATEDATE" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end"><Moment>{order.createdOn}</Moment></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-calendar fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.WISHDATE" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">{moment(order.wishedDeliveryDate).format('DD-MM-YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-calendar fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.PLANNEDDATE" })}</div>
                                                    </td>
                                                    <PolicyChecker policies={[enumPolicies.OrderAdmin]}>
                                                        <td className="fw-bold ">
                                                            <Flatpickr ref={fp}
                                                                onChange={onPlannedDeliveryDateClick}
                                                                options={{
                                                                    dateFormat: "d-m-Y",
                                                                    defaultDate: [order && moment(order.plannedDeliveryDate).format('DD-MM-YYYY')]
                                                                }}
                                                                className='form-control text-end form-control-solid'
                                                                placeholder='Select Date'
                                                            />
                                                        </td>
                                                    </PolicyChecker>
                                                    <PolicyChecker policies={[enumPolicies.OrderPersonnel, enumPolicies.OrderDealer]}>
                                                        <td className="fw-bold text-end">{moment(order.plannedDeliveryDate).format('DD-MM-YYYY')}</td>
                                                    </PolicyChecker>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-wallet fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                                <span className="path4"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.PAYMENTMETHOD" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">{order.paymentMethodName}</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-calendar fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.ORDERSTATUS" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">{order.orderStatusName}</td>
                                                </tr>
                                                <PolicyChecker policies={[enumPolicies.OrderAdmin]}>
                                                    {renderStatusList()}
                                                </PolicyChecker>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card card-flush py-4 flex-row-fluid">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>{intl.formatMessage({ id: "ORDER.FIELD.CUSTOMERDETAILS" })}</h2>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="table-responsive">
                                        <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                            <tbody className="fw-semibold text-gray-600">
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-profile-circle fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                                <span className="path3"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.CUSTOMER" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <button onClick={() => navigate(`/user-management/users/${order.user.id}`)} className="btn btn-link text-gray-600 text-hover-primary">{order.user.name} {order.user.surname}</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-sms fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.EMAIL" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">
                                                        <a href="#" className="text-gray-600 text-hover-primary">{order.user.email}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-muted">
                                                        <div className="d-flex align-items-center">
                                                            <i className="ki-duotone ki-phone fs-2 me-2">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                            </i>{intl.formatMessage({ id: "ORDER.FIELD.PHONE" })}</div>
                                                    </td>
                                                    <td className="fw-bold text-end">{order.user.phoneCode} {order.user.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <PolicyChecker policies={[enumPolicies.InvoiceRead, enumPolicies.InvoiceEdit, enumPolicies.InvoiceWrite]}>
                                    {renderDocuments()}
                                </PolicyChecker>
                            </div>
                        </div>
                        <div className="tab-content">
                            {renderSummary()}
                            <PolicyChecker policies={[enumPolicies.OrderAdmin, enumPolicies.OrderPersonnel]}>
                                {renderImages()}
                                {renderHistory()}
                            </PolicyChecker>
                        </div>
                    </div>
                </div>
            </div>

            {renderContentModal()}

            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        order: state.Order.order,
        items: state.Order.items,
        productList: state.Product.productList,
        statusList: state.Order.statusList,
        historyList: state.Order.historyList,
        modalLoading: state.Product.loading,
        images: state.Order.images,
        uploadedImageId: state.Order.uploadedImageId,
        loading: state.Order.loading,
        success: state.Order.success
    };
};

const mapActionsToProps = {
    clearState, getOrder, putOrder, getOrderItems, patchOrderContentStatus, getProductList, putOrderContent, deleteOrderContent, postOrderContent, getStatus, postStatus, getHistory, getImages, uploadImage, deleteImage
}

export default connect(mapStateToProps, mapActionsToProps)(Order)